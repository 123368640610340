export class AppRoute {
  // FIXME
  /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
  public static readonly Login = '/login';
  public static readonly ForgotPassword = '/forgot-password';
  public static readonly ResetPassword = '/reset-password';
  public static readonly SetPassword = '/set-password';
  public static readonly NotFound = '/not-found';
  public static readonly MobileVerification = '/mobile/verify-email';

  public static readonly AccountSetup = '/account-setup';
  public static readonly Organizations = '/organization-directory';
  public static readonly Users = '/user-directory';
  public static readonly Marketing = '/marketing';
  /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

  public static organization(orgId: string): string {
    return `/organization/${orgId}`;
  }

  public static organizationUsers(orgId: string): string {
    return `${this.organization(orgId)}/${AppRoute.Users}`;
  }

  public static location(orgId: string, locId: string): string {
    return `${this.organization(orgId)}/location/${locId}`;
  }

  public static locationSchedules(orgId: string, locId: string): string {
    return `${this.location(orgId, locId)}/schedule`;
  }

  public static locationSchedule(orgId: string, locId: string, schId: string): string {
    return `${this.location(orgId, locId)}/schedule/${schId}`;
  }

  public static locationUsers(orgId: string, locId: string): string {
    return `${this.location(orgId, locId)}/${AppRoute.Users}`;
  }

  public static asset(orgId: string, locId: string, assetId: string): string {
    return `${this.location(orgId, locId)}/asset/${assetId}`;
  }
}
