import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SharedModule } from '@shared';

import { ConfirmationDialogComponent, ConfirmationService } from './confirm';
import { ConsoleAppender, LOG_ZONE, LogCoreService, LogService, LogSettingsService, LogZone } from './logging';
import { CloudAppender } from './logging/appenders/cloud-appender.service';
import { NotificationDialogComponent, NotificationService } from './notify';
import { AssetService, AuthService, BrowserService, DashboardService, UserService } from './services';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [ConfirmationDialogComponent, NotificationDialogComponent],
  providers: [
    AssetService,
    AuthService,
    BrowserService,
    ConfirmationService,
    ConsoleAppender,
	CloudAppender,
    DashboardService,
    LogCoreService,
    LogService,
    LogSettingsService,
    NotificationService,
    UserService,
    { provide: LOG_ZONE, useValue: LogZone.GLOBAL },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
