// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  margin: 0 10%;
}`, "",{"version":3,"sources":["webpack://./src/app/mobile/email-verification/email-verification.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,6BAAA;EACA,aAAA;AACF","sourcesContent":[".body-wrapper {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  justify-content: space-evenly;\n  margin: 0 10%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
