export enum Hardware {
  ScentStream = 'SCENT_STREAM',
  ScentDirect = 'SCENT_DIRECT',
  ScentAirBreeze = 'SCENTAIR_BREEZE',
  ScentAirWhisperPro = 'SCENTAIR_WHISPER',
  ScentAirWhisperHome = 'SCENTAIR_WHISPER_HOME',
  ScentAirWhisperDistributor = 'SCENTAIR_WHISPER_DISTRIBUTOR',
  ScentAirWhisperIon = 'SCENTAIR_WHISPER_ION',
  ScentDirectBt = 'SCENT_DIRECT_BT',
  ScentAirWhisperMax = 'SCENTAIR_WHISPER_MAX',
  ScentDirectLl = 'SCENT_DIRECT_LL',
  ScentStreamLl = 'SCENT_STREAM_LL',
  ScentStreamBt = 'SCENT_STREAM_BT',
}

export interface HardwareProperties {
  name: string;
  htmlName: string;
  imagePath: string;
  hasPump: boolean;
  hasFan: boolean;
  hasScent: boolean;
  hasBacklight: boolean;
  hasRgbLight: boolean;
  hasBluetooth: boolean;
  isLinkLabsHardware: boolean;
  isPumpSpeedNozzleCount: boolean;
}

export function getHardwareProperties(value: Hardware | string): HardwareProperties {
  switch (value) {
    case Hardware.ScentDirect:
      return {
        name: 'ScentAir Direct',
        htmlName: 'ScentAir Direct<sup>&trade;</sup>',
        imagePath: 'assets/images/direct.svg',
        hasPump: true,
        hasFan: true,
        hasScent: true,
        hasBacklight: false,
        hasRgbLight: false,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: false,
        hasBluetooth: false,
      };
    case Hardware.ScentStream:
      return {
        name: 'ScentAir Stream',
        htmlName: 'ScentAir Stream<sup>&trade;</sup>',
        imagePath: 'assets/images/stream.svg',
        hasPump: true,
        hasFan: false,
        hasScent: true,
        hasBacklight: false,
        hasRgbLight: false,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: true,
        hasBluetooth: false,
      };
    case Hardware.ScentAirBreeze:
      return {
        name: 'ScentAir Breeze',
        htmlName: 'ScentAir Breeze<sup>&trade;</sup>',
        imagePath: 'assets/images/breeze.svg',
        hasPump: true,
        hasFan: true,
        hasScent: true,
        hasBacklight: true,
        hasRgbLight: false,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: false,
        hasBluetooth: true,
      };
    case Hardware.ScentAirWhisperPro:
      return {
        name: 'ScentAir Whisper Pro',
        htmlName: 'ScentAir Whisper<sup>&trade;</sup> Pro',
        imagePath: 'assets/images/whisper-pro.svg',
        hasPump: false,
        hasFan: true,
        hasScent: true,
        hasBacklight: true,
        hasRgbLight: true,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: false,
        hasBluetooth: true,
      };
    case Hardware.ScentAirWhisperHome:
      return {
        name: 'ScentAir Whisper Home',
        htmlName: 'ScentAir Whisper<sup>&trade;</sup> Home',
        imagePath: 'assets/images/whisper-home.svg',
        hasPump: false,
        hasFan: true,
        hasScent: true,
        hasBacklight: true,
        hasRgbLight: true,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: false,
        hasBluetooth: true,
      };
    case Hardware.ScentAirWhisperDistributor:
      return {
        name: 'Signature Scent Diffuser',
        htmlName: 'Signature Scent Diffuser',
        imagePath: 'assets/images/whisper-distributor.svg',
        hasPump: false,
        hasFan: true,
        hasScent: true,
        hasBacklight: true,
        hasRgbLight: true,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: false,
        hasBluetooth: true,
      };
    case Hardware.ScentAirWhisperIon:
      return {
        name: 'ScentAir ION Boost',
        htmlName: 'ScentAir ION Boost<sup>&trade;</sup>',
        imagePath: 'assets/images/whisper-ion.svg',
        hasPump: false,
        hasFan: true,
        hasScent: false,
        hasBacklight: true,
        hasRgbLight: true,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: false,
        hasBluetooth: true,
      };
    case Hardware.ScentDirectBt:
      return {
        name: 'ScentAir Direct',
        htmlName: 'ScentAir Direct<sup>&trade;</sup>',
        imagePath: 'assets/images/direct.svg',
        hasPump: true,
        hasFan: true,
        hasScent: true,
        hasBacklight: false,
        hasRgbLight: false,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: false,
        hasBluetooth: true,
      };
    case Hardware.ScentAirWhisperMax:
      return {
        name: 'ScentAir Whisper Max',
        htmlName: 'ScentAir Whisper<sup>&trade;</sup> Max',
        imagePath: 'assets/images/whisper-max.svg',
        hasPump: false,
        hasFan: true,
        hasScent: true,
        hasBacklight: true,
        hasRgbLight: true,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: false,
        hasBluetooth: true,
      };
    case Hardware.ScentDirectLl:
      return {
        name: 'ScentAir Direct',
        htmlName: 'ScentAir Direct<sup>&trade;</sup>',
        imagePath: 'assets/images/direct.svg',
        hasPump: true,
        hasFan: true,
        hasScent: true,
        hasBacklight: false,
        hasRgbLight: false,
        isLinkLabsHardware: true,
        isPumpSpeedNozzleCount: false,
        hasBluetooth: false,
      };
    case Hardware.ScentStreamLl:
      return {
        name: 'ScentAir Stream',
        htmlName: 'ScentAir Stream<sup>&trade;</sup>',
        imagePath: 'assets/images/stream-lora.svg',
        hasPump: true,
        hasFan: false,
        hasScent: true,
        hasBacklight: false,
        hasRgbLight: false,
        isLinkLabsHardware: true,
        isPumpSpeedNozzleCount: true,
        hasBluetooth: false,
      };
    case Hardware.ScentStreamBt:
      return {
        name: 'ScentAir Stream',
        htmlName: 'ScentAir Stream<sup>&trade;</sup>',
        imagePath: 'assets/images/stream.svg',
        hasPump: true,
        hasFan: false,
        hasScent: true,
        hasBacklight: false,
        hasRgbLight: false,
        isLinkLabsHardware: false,
        isPumpSpeedNozzleCount: true,
        hasBluetooth: true,
      };
    default:
      throw Error('Hardware type not supported');
  }
}
