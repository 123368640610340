import { Component } from '@angular/core';

import { LocalStorageUtil } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { UserDefaults } from '@shared';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(translateService: TranslateService) {
    translateService.setDefaultLang(LocalStorageUtil.getLocale() || UserDefaults.locale);
  }
}
