import { Pipe, PipeTransform } from '@angular/core';

const startTag = '<strong>';
const endTag = '</strong>';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, highlight: string): string {
    // TODO: This is probably not the right way to do this - I need guidence on a better way
    const trimmedHighlight = highlight.trim();
    if (trimmedHighlight.indexOf(' ') > 0) {
      // Don't handle highlights for terms with spaces
      return text;
    }
    const textLower = text.toLowerCase();
    const highlightLower = trimmedHighlight.toLowerCase();

    const split = textLower.split(highlightLower);
    if (split.length < 2) {
      return text;
    }
    let index = split[0].length;
    let finalText = text.substring(0, index);
    for (let i = 1; i < split.length; i++) {
      const indexPlus = index + trimmedHighlight.length;
      const highlighted = text.substring(index, indexPlus);
      index = indexPlus + split[i].length;
      finalText = finalText + startTag + highlighted + endTag + text.substring(indexPlus, index);
    }
    return finalText;
  }
}
