import { OrganizationAggregate } from './organization-aggregate.model';

export class Organization {
  name = '';
  id = '';
  aggregate: OrganizationAggregate = new OrganizationAggregate();

  constructor(id: string, name: string) {
    this.name = name;
    this.id = id;
  }

  asObject() {
    return {
      name: this.name,
      id: this.id,
      aggregate: { ...this.aggregate },
    };
  }
}
