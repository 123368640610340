import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, DashboardService, LogService, LogZone } from '@core';
import { AppRoute } from '@shared';

@Component({
  selector: 'sc-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.scss', './login.component.scss'],
})
export class LoginComponent implements OnInit {
  email = '';
  invalid = false;
  loading = false;
  password = '';
  returnUrl: string;

  public AppRoute = AppRoute;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private logService: LogService,
  ) {
    this.logService = this.logService.for(LogZone.AUTHENTICATION);
  }

  async login() {
    this.loading = true;
    this.logService.debug('Authenticating user ', { email: this.email });
    try {
      const creds = await this.authService.login(this.email, this.password);
      this.logService.debug('User authenticated ', creds);
      this.logService.info('User authenticated successfully.', { email: this.email });
      const authed = await this.dashboardService.goToDashboard(this.returnUrl);

      if (!authed) {
        this.invalid = true;
        this.loading = false;
      }
    } catch (error) {
      this.logService.error('Unable to authenticate user ', { error });
      this.password = '';
      this.invalid = true;
      this.loading = false;
    }
  }

  ngOnInit() {
    this.returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl;
  }
}
