import { LocationAggregate } from './location-aggregate.model';

export class Location {
  name = '';
  timezone = '';
  aggregate: LocationAggregate = new LocationAggregate();

  constructor(name: string, timezone: string) {
    this.name = name;
    this.timezone = timezone;
  }

  asObject() {
    return {
      name: this.name,
      timezone: this.timezone,
      aggregate: { ...this.aggregate },
    };
  }
}
