import { Directive, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { Permission, PermissionManager } from '@core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export interface SearchResults {
  extraText: SearchResultsExtraTextType;
  items: SearchResultItem[];
}

export enum SearchResultsExtraTextType {
  NO_RESULTS,
  TOO_MANY,
  NO_TEXT,
}

export interface SearchResultItem {
  type: SearchItemType;
  top: string;
  bottom: string;
  path: string;
  score: number;
}

export enum SearchItemType {
  ORGANIZATION,
  LOCATION,
  ASSET,
  USER,
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SearchComponent implements OnInit {
  @Input() modal: boolean;
  @ViewChild('search', { static: true }) searchElement: ElementRef;

  focused = false;
  changedSinceFocused = false;
  showSearchBar = true;

  text = new FormControl('');
  searchResults: Observable<SearchResults>;

  abstract searchFunction: (query: string) => Promise<SearchResultItem[]>;

  constructor(
    private modalService: NgbModal,
    private maxResults = 5,
    private minQueryLength = 3,
  ) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.focused) {
      switch (event.code) {
        case 'Escape':
          this.changedSinceFocused = false;
          break;
      }
    }
  }

  async ngOnInit() {
    this.showSearchBar = !PermissionManager.hasPermission(Permission.MANAGE_ADVERTISEMENT);
    this.searchResults = this.text.valueChanges.pipe(
      debounceTime(300),
      map((query) => query.trim()),
      distinctUntilChanged(),
      switchMap(async (query) => {
        if (query.length < this.minQueryLength) {
          return { extraText: SearchResultsExtraTextType.NO_TEXT, items: [] };
        }
        try {
          const items = await this.searchFunction(query);
          return this.processInitializedItems(items);
        } catch (err) {
          return this.processInitializedItems([]);
        }
      }),
      tap(() => {
        if (this.focused) {
          this.changedSinceFocused = true;
        }
      }),
    );
  }

  processInitializedItems(rawItems: SearchResultItem[]) {
    const items = rawItems.sort((first, second) => second.score - first.score);

    const result = {} as SearchResults;
    result.items = this.modal ? items : items.slice(0, this.maxResults);

    if (items.length < 1) {
      result.extraText = SearchResultsExtraTextType.NO_RESULTS;
    } else if (!this.modal && items.length > this.maxResults) {
      result.extraText = SearchResultsExtraTextType.TOO_MANY;
    } else {
      result.extraText = SearchResultsExtraTextType.NO_TEXT;
    }
    return result;
  }

  unfocus() {
    if (!this.modal) {
      this.focused = false;
      this.changedSinceFocused = false;
    }
  }

  clearSearch() {
    this.text.setValue('');
  }

  selectResult() {
    if (this.modal) {
      this.modalService.dismissAll();
    } else {
      this.unfocus();
      this.clearSearch();
    }
  }

  refocus() {
    this.searchElement.nativeElement.focus();
  }
}
