import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { LogService } from '../logging';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private logService: LogService) {}

  handle(params: MissingTranslationHandlerParams): string {
    this.logService.error('missing translation found', { key: params.key });
    return params.key;
  }
}
