import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { LogService, LogZone } from '../../core/logging';
import { toDotNotation } from '../utils/flatten.util';

export enum ApiState {
  Processing,
  Success,
  Failure,
}

@Injectable()
export class PersistenceService {
  public state = new BehaviorSubject<Map<string, ApiState>>(new Map<string, ApiState>());

  public constructor(
    private afs: AngularFirestore,
    private logService: LogService,
  ) {
    this.logService = logService.for(LogZone.FIRESTORE);
  }

  public runTransaction(
    fn: (transaction: firebase.firestore.Transaction) => Promise<unknown>,
    key: string = null,
  ): Promise<void> {
    this.updateState(key, ApiState.Processing);
    this.logService.debug('Transact', { fn });
    const request = this.afs.firestore.runTransaction(fn);
    return this.watch(key, request);
  }

  public getDoc(docRef: string): firebase.firestore.DocumentReference {
    this.logService.debug('Get doc', { docRef });
    return this.afs.firestore.doc(docRef);
  }

  public add<Tm extends unknown>(path: string, item: Tm, key: string = null): Promise<DocumentReference> {
    this.updateState(key, ApiState.Processing);
    this.logService.debug('Add', { path, item });
    const request = this.afs.collection(path).add(item);
    return this.watch(key, request);
  }

  public set<Tm extends unknown>(path: string, item: Tm, key: string = null): Promise<void> {
    this.updateState(key, ApiState.Processing);
    this.logService.debug('Set', { path, item });
    const request = this.afs.doc(path).set(item);
    return this.watch(key, request);
  }

  public patch<Tm extends unknown>(path: string, item: Partial<Tm>, key: string = null): Promise<void> {
    const update = toDotNotation(item);
    this.updateState(key, ApiState.Processing);
    this.logService.debug('Update', { path, item: update });
    const request = this.afs.doc(path).update(update);
    return this.watch(key, request);
  }

  public remove(path: string): Promise<void> {
    this.logService.debug('Remove', path);
    const request = this.afs.doc(path).delete();
    return this.watch(path, request);
  }

  public getState(key: string): Observable<ApiState> {
    return this.state.pipe(
      map((state) => state[key]),
      distinctUntilChanged(),
    );
  }

  private updateState(key: string, value: ApiState): void {
    if (!key) {
      return;
    }
    // avoid modifying the map by reference bc it may be referenced
    const state = new Map(this.state.value);
    state[key] = value;
    this.state.next(state);
  }

  private watch<T>(key: string, request: Promise<any>): Promise<T> {
    return request
      .then((x) => {
        this.updateState(key, ApiState.Success);
        return x;
      })
      .catch((x) => {
        // TODO: log
        this.updateState(key, ApiState.Failure);
        throw x;
      });
  }
}
