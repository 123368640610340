import { Component, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Location, mapCollectionWithId, Organization, Path } from '@data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalStorageUtil, Permission, PermissionManager } from '../../core/utils';
import { AppRoute } from '../constants';
import { ElasticsearchService, GlobalSearchResponse } from '../services/elasticsearch.service';
import { SearchComponent, SearchItemType, SearchResultItem } from './search.component';

@Component({
  selector: 'sc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchGlobalComponent extends SearchComponent implements OnDestroy {
  organizations: Map<string, Organization>;
  locations: Map<string, Location>;

  subscription: Subscription = new Subscription();

  constructor(
    db: AngularFirestore,
    private elasticsearchService: ElasticsearchService,
    modalService: NgbModal,
  ) {
    super(modalService);

    let organizations: Observable<Map<string, Organization>>;
    let locations: Observable<Map<string, Location>>;

    this.elasticsearchService.prepareSearch();
    const claims = LocalStorageUtil.getClaims();
    if (PermissionManager.hasPermission(Permission.READ_ORGANIZATION_DIRECTORY)) {
      organizations = db
        .collection<Organization>(Path.organizations())
        .snapshotChanges()
        .pipe(mapCollectionWithId<Organization>());
      locations = db
        .collectionGroup<Location>(Path.locationsCollectionGroup())
        .snapshotChanges()
        .pipe(mapCollectionWithId<Location>());
    } else if (claims.locId) {
      organizations = of(new Map<string, Organization>());
      locations = db
        .doc<Location>(Path.location(claims.orgId, claims.locId))
        .valueChanges()
        .pipe(map((loc) => new Map([[claims.locId, loc]])));
    } else {
      organizations = db
        .doc<Organization>(Path.organization(claims.orgId))
        .valueChanges()
        .pipe(map((org) => new Map([[claims.orgId, org]])));
      locations = db
        .collection<Location>(Path.locations(claims.orgId))
        .snapshotChanges()
        .pipe(mapCollectionWithId<Location>());
    }

    this.subscription.add(
      organizations.subscribe((orgs) => {
        this.organizations = orgs;
      }),
    );
    this.subscription.add(
      locations.subscribe((locs) => {
        this.locations = locs;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchFunction = async (query: string) => {
    const response = await this.elasticsearchService.searchGlobal(query);
    return this.constructSearchItems(response);
  };

  /**
   * Note that this sets the priority that the items show up in the response
   */

  constructSearchItems(response: GlobalSearchResponse): SearchResultItem[] {
    const items = [] as SearchResultItem[];
    if (response.orgs) {
      for (const org of response.orgs) {
        items.push({
          type: SearchItemType.ORGANIZATION,
          top: org.name,
          bottom: org.id,
          path: `${AppRoute.organization(org.fsId)}`,
          score: org.score,
        });
      }
    }
    if (response.locs) {
      for (const location of response.locs) {
        const org = this.organizations.get(location.fsOrganizationId);
        items.push({
          type: SearchItemType.LOCATION,
          top: location.name,
          bottom: org.name,
          path: `${AppRoute.location(location.fsOrganizationId, location.fsId)}`,
          score: location.score,
        });
      }
    }
    for (const asset of response.assets) {
      let top = asset.mac;
      if (asset.name && asset.name !== asset.mac) {
        top = `${asset.name} (${asset.mac})`;
      }
      const org = this.organizations.get(asset.fsOrganizationId);
      const loc = this.locations.get(asset.fsLocationId);
      let bottom: string;
      if (org && loc) {
        bottom = `${org.name} | ${loc.name}`;
      } else if (org) {
        bottom = org.name;
      } else if (loc) {
        bottom = loc.name;
      }
      items.push({
        type: SearchItemType.ASSET,
        top,
        bottom,
        path: `${AppRoute.asset(asset.fsOrganizationId, asset.fsLocationId, asset.mac)}`,
        score: asset.score,
      });
    }
    return items;
  }
}
