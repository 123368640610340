// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-password {
  display: block;
  margin: 0.25rem 0.125rem;
  text-align: right;
  font-size: 0.875rem;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/login/login.component.scss"],"names":[],"mappings":"AAGA;EACE,cAAA;EACA,wBAAA;EACA,iBAAA;EACA,mBAAA;AAFF","sourcesContent":["@import 'variables';\n@import 'functions';\n\n.forgot-password {\n  display: block;\n  margin: rem(4) rem(2);\n  text-align: right;\n  font-size: rem(14);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
