/* eslint-disable @typescript-eslint/member-ordering */
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';

import { PersistenceService } from '../services';

/**
 * Relates a DAO to its snapshot and stream source.
 * Used for entities that are streamed from a collection.
 */
interface CollectionDocDao<Tm extends unknown, Td extends BaseDAO<Tm>> {
  data: BehaviorSubject<Tm>;
  dao: Td;
}

/**
 * Strategy for how to get the snapshot and stream for a DAO.
 */
interface DaoValue<Tm extends unknown> {
  snapshot: Tm;
  stream: Observable<Tm>;
}

/**
 * Strategy for how to get the snapshot and stream for
 * a DAO that is streamed as part of a collection.
 */
class CollectionDaoValue<Tm extends unknown> implements DaoValue<Tm> {
  public get snapshot(): Tm {
    return this._data.value;
  }

  public get stream(): Observable<Tm> {
    return this._data;
  }

  constructor(private _data: BehaviorSubject<Tm>) {}
}

/**
 * Factory for creating a DAO tree starting with a root doc.
 */
@Injectable()
export class DaoFactory {
  public constructor(
    private afs: AngularFirestore,
    private auth: AngularFireAuth,
    private persistenceService: PersistenceService,
  ) {}

  /**
   * Create the root DAO for the given path.
   *
   * @param DAO DAO constructor
   * @param path Path to the root doc.
   */
  public build<Tm extends unknown, Td extends BaseDAO<Tm>>(
    DAO: new (...args: any[]) => Td,
    path: string,
  ): Observable<Td> {
    return DaoFactory.build(this.afs, this.auth, this.persistenceService, DAO, path);
  }

  public static build<Tm extends unknown, Td extends BaseDAO<Tm>>(
    afs: AngularFirestore,
    auth: AngularFireAuth,
    persistenceService: PersistenceService,
    DAO: new (...args: any[]) => Td,
    path: string,
  ): Observable<Td> {
    const stop = auth.authState.pipe(
      filter((x) => !x), // Logged Out
      map((x) => !!x),
    );
    const doc = afs.doc<Tm>(path);
    let dao: Td;
    let stream: BehaviorSubject<Tm>;

    return doc.snapshotChanges().pipe(
      takeUntil(stop),
      map((action) => {
        if (!action.payload.exists) return null;
        const { id } = action.payload.ref;
        const snapshot = action.payload.data();
        if (!dao) {
          stream = new BehaviorSubject<Tm>(snapshot);
          const value = new CollectionDaoValue(stream);
          dao = new DAO(afs, stop, persistenceService, id, path, value);
          return dao;
        }
        stream.next(snapshot);
        return dao;
      }),
      shareReplay(1),
    );
  }

  public add<Tm extends unknown>(path: string, item: Tm): Promise<void> {
    return this.afs
      .collection(path)
      .add(item)
      .then(() => {});
  }

  public set<Tm extends unknown>(path: string, item: Tm): Promise<void> {
    return this.afs
      .doc(path)
      .set(item)
      .then(() => {});
  }

  public patch<Tm extends unknown>(path: string, item: Partial<Tm>): Promise<void> {
    return this.afs
      .doc(path)
      .update(item)
      .then(() => {});
  }

  public remove(path: string): Promise<void> {
    return this.afs
      .doc(path)
      .delete()
      .then(() => {});
  }
}

/**
 * Wrapper for a firestore entity that allows
 * lazy access to its sub-collections.
 */
export class BaseDAO<Tm extends unknown> {
  /**
   * Last known value.
   */
  public get snapshot(): Tm {
    return this._data.snapshot;
  }

  /**
   * Stream of values.
   */
  public get stream(): Observable<Tm> {
    return this._data.stream;
  }

  public constructor(
    protected _afs: AngularFirestore,
    protected _stop: Observable<any>,
    protected _persistenceService: PersistenceService,
    public id: string,
    public path: string,
    protected _data: DaoValue<Tm>,
  ) {}

  /**
   * Create an observable of DAOs for the sub-collection.
   *
   * @param DAO DAO constructor.
   * @param path Path to the collection.
   */
  // eslint-disable-next-line @typescript-eslint/no-shadow
  protected initCollection<Tm extends unknown, Td extends BaseDAO<Tm>>(
    DAO: new (...args: any[]) => Td,
    path: string,
  ): Observable<Td[]> {
    return DaoCollectionFactory.initCollection(this._afs, this._stop, this._persistenceService, DAO, path);
  }
}

/**
 * Static class used to generate DAO collections.
 */
@Injectable()
export class DaoCollectionFactory {
  public constructor(
    private afs: AngularFirestore,
    private auth: AngularFireAuth,
    private persistenceService: PersistenceService,
  ) {}

  /**
   * Create the root DAO for the given path.
   *
   * @param DAO DAO constructor
   * @param path Path to the root doc.
   */
  public build<Tm extends unknown, Td extends BaseDAO<Tm>>(
    DAO: new (...args: any[]) => Td,
    path: string,
  ): Observable<Td[]> {
    const stop = this.auth.authState.pipe(
      filter((x) => !x), // Logged Out
      map((x) => !!x),
    );
    return DaoCollectionFactory.initCollection(this.afs, stop, this.persistenceService, DAO, path);
  }

  /**
   * Create an observable of DAOs for the sub-collection.
   *
   * @param DAO DAO constructor.
   * @param path Path to the collection.
   */
  public static initCollection<Tm extends unknown, Td extends BaseDAO<Tm>>(
    afs: AngularFirestore,
    stop: Observable<any>,
    persistenceService: PersistenceService,
    DAO: new (...args: any[]) => Td,
    path: string,
  ): Observable<Td[]> {
    const collection = afs.collection<Tm>(path);
    const daoMap = new Map<string, CollectionDocDao<Tm, Td>>();
    /**
     * Provides a list of the last actions performed for each entity.
     * Upon initial subscription all will be of type 'added'.
     * Upon one element being updated that one will be of type 'modified'
     * and the others will be of type 'added' or 'modified' if they were previously.
     * Type 'remove' doesn't come through.
     */
    return collection.snapshotChanges().pipe(
      takeUntil(stop),
      map((actions) => {
        this.removeMissing(daoMap, actions);
        actions.forEach((action) => {
          this.put(afs, stop, persistenceService, DAO, daoMap, action);
        });
        // create a new array to change the reference
        return Array.from(daoMap.values()).map((x) => x.dao);
      }),
      shareReplay(1),
    );
  }

  /**
   * Remove daos that are missing from the new state.
   *
   * @param daos
   * @param actions
   */
  private static removeMissing<Tm extends unknown, Td extends BaseDAO<Tm>>(
    daoMap: Map<string, CollectionDocDao<Tm, Td>>,
    actions: DocumentChangeAction<Tm>[],
  ): void {
    const newStatePaths = new Set<string>(actions.map((x) => x.payload.doc.ref.path));
    daoMap.forEach((wrap) => {
      const { path } = wrap.dao;
      if (!newStatePaths.has(path)) {
        daoMap.delete(path);
      }
    });
  }

  /**
   * Add or update DAOs.
   *
   * @param DAO DAO constructor.
   * @param daos Current daos.
   * @param action Entity metadata.
   */
  private static put<Tm extends unknown, Td extends BaseDAO<Tm>>(
    afs: AngularFirestore,
    stop: Observable<any>,
    persistenceService: PersistenceService,
    DAO: new (...args: any[]) => Td,
    daos: Map<string, CollectionDocDao<Tm, Td>>,
    action: DocumentChangeAction<Tm>,
  ): void {
    const { path } = action.payload.doc.ref;
    const snapshot = action.payload.doc.data();

    // set
    let wrap = daos.get(path);
    if (wrap != null) {
      wrap.data.next(snapshot);
      return;
    }

    // add
    const { id } = action.payload.doc.ref;
    const stream = new BehaviorSubject<Tm>(snapshot);
    const value = new CollectionDaoValue(stream);
    const dao = new DAO(afs, stop, persistenceService, id, path, value);
    wrap = { data: stream, dao } as CollectionDocDao<Tm, Td>;
    daos.set(path, wrap);
  }
}
