import { Observable } from 'rxjs';

import { Location } from '../models';
import { AssetDAO } from './asset.dao';
import { BaseDAO } from './base.dao';
import { ScheduleDAO } from './schedule.dao';

export class LocationDAO extends BaseDAO<Location> {
  private _assets: Observable<AssetDAO[]>;
  private _schedules: Observable<ScheduleDAO[]>;

  public get assets(): Observable<AssetDAO[]> {
    if (this._assets == null) {
      const path = `${this.path}/assets`;
      this._assets = this.initCollection(AssetDAO, path);
    }
    return this._assets;
  }

  public get schedules(): Observable<ScheduleDAO[]> {
    if (this._schedules == null) {
      const path = `${this.path}/schedules`;
      this._schedules = this.initCollection(ScheduleDAO, path);
    }
    return this._schedules;
  }
}
