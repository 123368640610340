import { firstValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class UserService {
  constructor(private firebaseFunctions: AngularFireFunctions) {}

  async sendNewUserEmail(params: { userPath: string }) {
    const sendNewUserEmail = this.firebaseFunctions.httpsCallable('sendNewUserEmail');
    return firstValueFrom(sendNewUserEmail(params));
  }
}
