import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
	NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerModule, NgbDropdownModule, NgbModalModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AggregateComponent } from './aggregate/aggregate.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BtnProcessingDirective, ClickOutsideDirective } from './directives';
import { FooterComponent } from './footer/footer.component';
import { HeaderTabsComponent } from './header-tabs/header-tabs.component';
import { DatepickerConfig, MomentDateFormatter } from './ng-bootstrap';
import { PaginatorComponent } from './paginator';
import {
	DatetimePipe, HighlightPipe, LocalePipe, RoleNamePipe, TempNamePipe, TempUnitPipe, TimePipe, TimezonePipe,
	TranslateAssetFirmwareStatusPipe,
} from './pipes';
import { CloudImagePipe } from './pipes/cloud-image.pipe';
import { TranslateAssetStatusPipe } from './pipes/translate-asset-status.pipe';
import { SearchGlobalComponent } from './search/search-global.component';
import { SearchModalComponent } from './search/search-modal/search-modal.component';
import { SearchResultsComponent } from './search/search-results/search-results.component';
import { SearchUserComponent } from './search/search-user.component';
import { ElasticsearchService } from './services/elasticsearch.service';
import { WeekPickerComponent } from './week-picker/week-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [
    AggregateComponent,
    BreadcrumbComponent,
    BtnProcessingDirective,
    ClickOutsideDirective,
    DatetimePipe,
    TimePipe,
    FooterComponent,
    LocalePipe,
    PaginatorComponent,
    SearchGlobalComponent,
    SearchUserComponent,
    TempNamePipe,
    TempUnitPipe,
    TimezonePipe,
    WeekPickerComponent,
    SearchResultsComponent,
    HighlightPipe,
    RoleNamePipe,
    SearchModalComponent,
    TranslateAssetStatusPipe,
    TranslateAssetFirmwareStatusPipe,
    HeaderTabsComponent,
    CloudImagePipe,
  ],
  providers: [
    LocalePipe,
    TempNamePipe,
    TempUnitPipe,
    TimezonePipe,
    DatetimePipe,
    TimePipe,
    DecimalPipe,
    HighlightPipe,
    RoleNamePipe,
    TranslateAssetStatusPipe,
    TranslateAssetFirmwareStatusPipe,
    { provide: NgbDatepickerConfig, useClass: DatepickerConfig },
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    ElasticsearchService,
  ],
  exports: [
    AggregateComponent,
    BreadcrumbComponent,
    BtnProcessingDirective,
    ClickOutsideDirective,
    CommonModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    DatetimePipe,
    TimePipe,
    FooterComponent,
    FormsModule,
    HeaderTabsComponent,
    LocalePipe,
    PaginatorComponent,
    SearchGlobalComponent,
    SearchUserComponent,
    TempNamePipe,
    TempUnitPipe,
    TimezonePipe,
    TranslateModule,
    WeekPickerComponent,
    HighlightPipe,
    RoleNamePipe,
    TranslateAssetStatusPipe,
    TranslateAssetFirmwareStatusPipe,
    CloudImagePipe,
  ],
})
export class SharedModule {}
