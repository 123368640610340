// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.nav-pills {
  font-size: 1.125rem;
}

ul.nav-pills > li.nav-item > a.nav-link.active {
  color: #456c9b;
  background-color: #54b0cb;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/unauthenticated/unauthenticated.component.scss","webpack://./src/assets/styles/variables.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;AAFF;;AAIA;EACE,cCJU;EDKV,yBCNc;EDOd,iBAAA;AADF","sourcesContent":["@import 'variables';\n@import 'functions';\n\nul.nav-pills {\n  font-size: rem(18);\n}\nul.nav-pills > li.nav-item > a.nav-link.active {\n  color: $primary;\n  background-color: $secondary;\n  font-weight: bold;\n}\n","@import './functions.scss';\n\n$standard-blue: #54b0cb;\n$dark-blue: #456c9b;\n$green: #6fa163;\n$yellow: #f6af0b;\n$red: #e84e25;\n$min-contrast-ratio: 3;\n\n$white: #fff !default;\n$gray-100: #f8f9fa !default;\n$gray-200: #e9ecef !default;\n$gray-300: #dee2e6 !default;\n$gray-400: #ced4da !default;\n$gray-500: #adb5bd !default;\n$gray-550: #8d959d;\n$gray-600: #6c757d !default;\n$gray-700: #495057 !default;\n$gray-800: #343a40 !default;\n$gray-900: #212529 !default;\n$black: #000 !default;\n\n$primary: $dark-blue;\n$secondary: $standard-blue;\n$body-color: $gray-600;\n$light: $gray-300;\n$text-muted: $gray-550;\n\n$h1-font-size: rem(28);\n$h2-font-size: rem(24);\n$h3-font-size: rem(19);\n\n$input-btn-padding-y: 0.1875rem;\n$input-btn-padding-x: 0.375rem;\n$input-color: $gray-600;\n$input-placeholder-color: $gray-500;\n\n$grid-gutter-width: 12px;\n$border-color: $gray-200;\n\n// table\n$table-cell-padding: 0.625rem;\n\n$banner-height: rem(60);\n\n$form-switch-color: $secondary;\n$form-switch-focus-color: $secondary;\n$form-check-input-focus-box-shadow: $secondary;\n$form-check-input-checked-bg-color: $secondary;\n\n@import '../../../node_modules/bootstrap/scss/_functions';\n@import '../../../node_modules/bootstrap/scss/_variables';\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
