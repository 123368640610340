import { Pipe, PipeTransform } from '@angular/core';
import { ExternalRole, InternalRole } from '@data';
import { TranslateService } from '@ngx-translate/core';

import { Role } from '../../core/services/auth.service';

// Set pure to false as result depends on set locale,
// which does not change input value, only the output.
@Pipe({ name: 'roleName', pure: false })
export class RoleNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(role: Role | ExternalRole | InternalRole): string {
    const roleName = role.replace('ENT_', '');
    return this.translateService.instant(`ROLE.${roleName}`);
  }
}
