import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormGroupDirective, NgForm, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

import { TranslateModule } from '@ngx-translate/core';

import { AccountLinkingComponent } from './account-linking/account-linking.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';

export class SubmittedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && isSubmitted);
  }
}

@NgModule({
  declarations: [AccountLinkingComponent, EmailVerificationComponent],
  imports: [CommonModule, TranslateModule, MatButtonModule, MatInputModule, ReactiveFormsModule],
  providers: [{ provide: ErrorStateMatcher, useClass: SubmittedErrorStateMatcher }],
})
export class MobileModule {}
