import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticationGuard, firebaseGuard, loginGuard } from '@core';

import {
  ForgotPasswordComponent,
  LoginComponent,
  ResetPasswordComponent,
  SignupComponent,
  UnauthenticatedComponent,
} from './auth';
import { CreatePasswordComponent } from './auth/create-password/create-password.component';
import { accountLinkingGuard } from './core/guards/account-linking.guard';
import { emailVerificationGuard } from './core/guards/email-verification.guard';
import { AccountLinkingComponent } from './mobile/account-linking/account-linking.component';
import { EmailVerificationComponent } from './mobile/email-verification/email-verification.component';

const routes: Routes = [
  {
    path: 'login',
    component: UnauthenticatedComponent,
    canActivate: [loginGuard],
    children: [{ path: '', component: LoginComponent }],
  },
  {
    path: 'signup',
    component: UnauthenticatedComponent,
    canActivate: [loginGuard],
    children: [{ path: '', component: SignupComponent }],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'set-password',
    component: CreatePasswordComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'auth',
    component: LoginComponent,
    canActivate: [firebaseGuard],
  },
  {
    path: 'account-linking',
    component: AccountLinkingComponent,
    canActivate: [accountLinkingGuard],
  },
  {
    path: 'mobile/verify-email',
    component: EmailVerificationComponent,
    canActivate: [emailVerificationGuard],
  },
  {
    path: '',
    loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule),
    canMatch: [authenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
