import { DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

import { Hardware } from '../enums';
import { AssetConfig, RgbColor } from './config.model';
import { Event } from './event.model';

export interface Asset {
  name: string;
  mac: string;
  pin: string;
  isOnline: boolean;
  locationScheduleRef: DocumentReference;
  otaJobId: string;
  meta: AssetMeta;
  calibration: {
    hardware: Hardware;
    pumpSpeed1: number;
    pumpSpeed2: number;
    pumpSpeed3: number;
    fanSpeed1: number;
    fanSpeed10: number;
  };
  config: AssetConfig;
  state?: {
    isOff: boolean;
    isOffline: boolean;
    isDuctOff: boolean;
    ductOverride: boolean;
    isInEvent: boolean;
    pumpSpeed: number;
    fanSpeed: number;
    isPaused: boolean;
    pausedTill: firebase.firestore.Timestamp;
    firmwareVersion: string;
    isBacklightOn: boolean;
    rgbLight: RgbColor;
  };
  events: Event[];
  telemetry: {
    ipAddr: string;
    mcuTemp: number;
    wifiStrength: number;
    bootCount: number;
    deviceUptime: number;
    wifiUptime: number;
    pumpRunTime: number;
    fanRunTime: number;
    powerSupplyVoltage: number;
    pump1Current: number;
    pump2Current: number;
    fanCurrent: number;
    maxMcuTemp: number;
    minMcuTemp: number;
    maxPump1Current: number;
    minPump1Current: number;
    maxPump2Current: number;
    minPump2Current: number;
    maxFanCurrent: number;
    minFanCurrent: number;
  };
}

export interface AssetMeta {
  isConfigSynced: boolean;
  configAck: firebase.firestore.Timestamp;
  configUpdated: firebase.firestore.Timestamp;
  configUpdatedBy: string;
  connectivityUpdated: firebase.firestore.Timestamp;
  firmwareUpdateRequested: firebase.firestore.Timestamp;
  stateUpdated: firebase.firestore.Timestamp;
  telemetryUpdated: firebase.firestore.Timestamp;
  lockCodeUpdatedBy?: string;
}
