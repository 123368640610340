import { LoggingConfig } from 'environments/environment-config';

import { Inject, Injectable } from '@angular/core';

import { LogCoreService } from './log-core.service';
import { LogLevel } from './log-level.model';
import { LogMessage } from './log-message.model';
import { LogSettingsService } from './log-settings.service';
import { LOG_ZONE } from './log-zone';

/**
 * Service used to persist log messages.
 *
 * @export
 * @class LogService
 */
@Injectable()
export class LogService {
  for: (zone: string) => LogService;

  private zone: string;
  private config: LoggingConfig;

  // need a provider for log zone for the --prod build
  constructor(
    private logCore: LogCoreService,
    logSettings: LogSettingsService,
    @Inject(LOG_ZONE) zone?: string,
  ) {
    this.zone = zone;
    logSettings.config.subscribe((config) => this.onSettingsChange(config));
    // setup method to create a zoned logger
    this.for = (z: string) => new LogService(logCore, logSettings, z);
  }

  /**
   * Is trace logging enabled?
   *
   * @readonly
   * @memberof LogService
   */
  public get isTraceEnabled() {
    return this.config.logLevel <= LogLevel.Trace;
  }

  /**
   * Is debug logging enabled?
   *
   * @readonly
   * @memberof LogService
   */
  public get isDebugEnabled() {
    return this.config.logLevel <= LogLevel.Debug;
  }

  /**
   * Is info logging enabled?
   *
   * @readonly
   * @memberof LogService
   */
  public get isInfoEnabled() {
    return this.config.logLevel <= LogLevel.Info;
  }

  /**
   * Is warn logging enabled?
   *
   * @readonly
   * @memberof LogService
   */
  public get isWarnEnabled() {
    return this.config.logLevel <= LogLevel.Warn;
  }

  /**
   * Is error logging enabled?
   *
   * @readonly
   * @memberof LogService
   */
  public get isErrorEnabled() {
    return this.config.logLevel <= LogLevel.Error;
  }

  /**
   * Is fatal logging enabled?
   *
   * @readonly
   * @memberof LogService
   */
  public get isFatalEnabled() {
    return this.config.logLevel <= LogLevel.Fatal;
  }

  /**
   * Log a trace level message if that level is enabled.
   *
   * @param message Message to log
   * @param [data=null] Data that provides context for the message.
   * @param [exception=null] Error related to the message.
   * @memberof LogService
   */
  public trace(message: string, data: any = null, exception: Error = null): void {
    this.log(LogLevel.Trace, message, data, exception);
  }

  /**
   * Log a debug level message if that level is enabled.
   *
   * @param message Message to log
   * @param [data=null] Data that provides context for the message.
   * @param [exception=null] Error related to the message.
   * @memberof LogService
   */
  public debug(message: string, data: any = null, exception: Error = null): void {
    this.log(LogLevel.Debug, message, data, exception);
  }

  /**
   * Log an info level message if that level is enabled.
   *
   * @param message Message to log
   * @param [data=null] Data that provides context for the message.
   * @param [exception=null] Error related to the message.
   * @memberof LogService
   */
  public info(message: string, data: any = null, exception: Error = null): void {
    this.log(LogLevel.Info, message, data, exception);
  }

  /**
   * Log a warn level message if that level is enabled.
   *
   * @param message Message to log
   * @param [data=null] Data that provides context for the message.
   * @param [exception=null] Error related to the message.
   * @memberof LogService
   */
  public warn(message: string, data: any = null, exception: Error = null): void {
    this.log(LogLevel.Warn, message, data, exception);
  }

  /**
   * Log an error level message if that level is enabled.
   *
   * @param message Message to log
   * @param [data=null] Data that provides context for the message.
   * @param [exception=null] Error related to the message.
   * @memberof LogService
   */
  public error(message: string, data: any = null, exception: Error = null): void {
    this.log(LogLevel.Error, message, data, exception);
  }

  /**
   * Log a fatal level message if that level is enabled.
   *
   * @param message Message to log
   * @param [data=null] Data that provides context for the message.
   * @param [exception=null] Error related to the message.
   * @memberof LogService
   */
  public fatal(message: string, data: any = null, exception: Error = null): void {
    this.log(LogLevel.Fatal, message, data, exception);
  }

  /**
   * Write a log message for the given level if that level is enabled.
   *
   * @private
   * @param level
   * @param message
   * @param [data=null]
   * @param [exception=null]
   * @memberof LogService
   */
  private log(level: LogLevel, message: string, data: any = null, exception: Error = null): void {
    // filter by log level
    if (this.config.logLevel > level) {
      return;
    }
    // filter by zone
    if (this.config.zones.size > 0 && (!this.zone || !this.config.zones.has(this.zone))) {
      return;
    }

    const logMessage = new LogMessage();
    logMessage.zone = this.zone;
    logMessage.level = level;
    logMessage.timestamp = new Date();
    logMessage.message = message;
    logMessage.data = data;
    logMessage.exception = exception;

    this.logCore.log(logMessage);
  }

  /**
   * Update the log settings.
   *
   * @private
   * @param config
   * @memberof LogService
   */
  private onSettingsChange(config: LoggingConfig): void {
    this.config = config;
  }
}
