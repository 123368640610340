import { Component, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Subscription } from 'rxjs';

import { mapCollectionWithId, Organization, Path } from '@data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppRoute } from '../constants';
import { ElasticsearchService, UserSearchResponse } from '../services/elasticsearch.service';
import { SearchComponent, SearchItemType, SearchResultItem } from './search.component';

@Component({
  selector: 'sc-search-users',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchUserComponent extends SearchComponent implements OnDestroy {
  organizations: Map<string, Organization>;
  subscription: Subscription = new Subscription();

  constructor(
    db: AngularFirestore,
    private elasticsearchService: ElasticsearchService,
    modalService: NgbModal,
  ) {
    super(modalService);

    this.elasticsearchService.prepareSearch();

    const organizations = db
      .collection<Organization>(Path.organizations())
      .snapshotChanges()
      .pipe(mapCollectionWithId<Organization>());

    this.subscription.add(
      organizations.subscribe((orgs) => {
        this.organizations = orgs;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchFunction = async (query: string) => {
    const response = await this.elasticsearchService.searchUsers(query);
    return this.constructSearchItems(response);
  };

  constructSearchItems(response: UserSearchResponse): SearchResultItem[] {
    const items: SearchResultItem[] = [];
    for (const user of response.users) {
      const org = this.organizations.get(user.fsOrganizationId);
      items.push({
        type: SearchItemType.USER,
        top: `${user.displayName} | ${user.email}`,
        bottom: org.name + (org.id ? ` (${org.id})` : ''),
        path: `${AppRoute.organization(user.fsOrganizationId)}`,
        score: user.score,
      });
    }
    return items;
  }
}
