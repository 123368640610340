import { DayOfWeek } from '../enums';

export interface Event {
  eventId: string;
  scentLevel: number;
  scentTime: number;
  startHour: number;
  startMinute: number;
  stopHour: number;
  stopMinute: number;
  daysOfWeek: DayOfWeek[];
}
