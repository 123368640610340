import { LogLevel } from '../app/core/logging';
import { EnvironmentConfig } from './environment-config';

export const environment = {
  name: 'dev',
  production: false,
  emulateFirestore: false,
  emulateFunctions: false,
  firebase: {
    apiKey: 'AIzaSyAv00pbpncw5Wtkfus2ap8dD1W06Ap-4IY',
    authDomain: 'scentconnect-dev.firebaseapp.com',
    databaseURL: 'https://scentconnect-dev.firebaseio.com',
    projectId: 'scentconnect-dev',
    storageBucket: 'scentconnect-dev.appspot.com',
    messagingSenderId: '811435363953',
    appId: '1:811435363953:web:998e42e1055f4f31',
    measurementId: 'G-87NGJGDJQ6',
  },
  logging: {
    logLevel: LogLevel.All,
    zones: new Set<string>([]),
  },
} as EnvironmentConfig;
