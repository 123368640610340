import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, LogService, LogZone } from '@core';
import { AppRoute } from '@shared';

enum State {
  Normal = 'NORMAL',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

@Component({
  selector: 'sc-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../auth.scss', './reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  code: string;
  continueUrl: string;
  email: string;
  password = '';
  confirmPassword = '';
  invalidMatch = false;
  invalidLength = false;
  loading = false;
  state: State = State.Normal;
  reqPasswordLength = 8;

  public AppRoute = AppRoute;
  public State = State;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private logService: LogService,
  ) {
    this.logService = this.logService.for(LogZone.AUTHENTICATION);
  }

  async verifyCode() {
    this.logService.debug('Validating user code... ', { code: this.code, email: this.email });
    try {
      if (!this.code) {
        this.logService.error('User accessed reset password with no code.');
        this.state = State.Error;
        return;
      }
      this.email = await this.authService.verifyPasswordResetCode(this.code);
      this.logService.debug('Validated user code. ', { email: this.email });
    } catch (error) {
      this.logService.error('Unable to validate user code. ', { error });
      this.state = State.Error;
    }
  }

  async updatePassword() {
    this.authService.getCurrentUser();
    this.invalidMatch = this.password !== this.confirmPassword;
    this.invalidLength = !this.invalidMatch && this.password.length < this.reqPasswordLength;

    if (this.invalidMatch || this.invalidLength) {
      return;
    }

    this.loading = true;
    this.logService.debug('Updating user password...', { email: this.email, continueUrl: this.continueUrl });
    try {
      const confirmation = await this.authService.confirmPasswordReset(this.code, this.password);
      this.logService.debug('Updated user password. ', confirmation);
      this.state = State.Success;
    } catch (error) {
      this.logService.error('Unable to update user password. ', { error });
      this.state = State.Error;
    }
    this.loading = false;
  }

  ngOnInit() {
    this.code = this.activatedRoute.snapshot.queryParams.oobCode;
    this.continueUrl = this.activatedRoute.snapshot.queryParams.continueUrl;
    this.verifyCode();
  }
}
