import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { AppRoute } from '@shared';

// eslint-disable-next-line func-style
export const emailVerificationGuard: CanActivateFn = (next: ActivatedRouteSnapshot) => {
  if (next.queryParams.continueUrl) {
    return true;
  } else {
    inject(Router).navigate([AppRoute.Login]);
    return false;
  }
};
