import { Component } from '@angular/core';
import { AppRoute } from '@shared';

@Component({
  selector: 'sc-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  public AppRoute = AppRoute;
}
