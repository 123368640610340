import { LogLevel } from './log-level.model';

export class LogMessage {
  public zone: string;
  public level: LogLevel;
  public timestamp: Date;
  public message: string;
  public data: any;
  public exception: Error;

  /**
   * Get the human readable version of the log level.
   *
   * @readonly
   * @memberof LogMessage
   */
  // eslint-disable-next-line consistent-return
  public get levelText() {
    switch (this.level) {
      case LogLevel.All:
        return 'ALL';
      case LogLevel.Trace:
        return 'TRACE';
      case LogLevel.Debug:
        return 'DEBUG';
      case LogLevel.Info:
        return 'INFO';
      case LogLevel.Warn:
        return 'WARN';
      case LogLevel.Error:
        return 'ERROR';
      case LogLevel.Fatal:
        return 'FATAL';
      case LogLevel.Off:
        return 'NONE';
    }
  }
}
