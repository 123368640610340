import { Pipe, PipeTransform } from '@angular/core';
import { TempUnit } from '@data';

@Pipe({ name: 'tempUnit' })
export class TempUnitPipe implements PipeTransform {
  transform(value: number, unit: TempUnit): string {
    // assume that the source value is in celsius bc it always is
    if (unit === TempUnit.Fahrenheit) {
      const f = (value * 9) / 5 + 32;
      return `${f} °F`;
    }

    return `${value} °C`;
  }
}
