import firebase from 'firebase/compat/app';

export interface AssetConfig {
  ductOverride: boolean;
  pumpSpeed: number;
  fanSpeed: number;
  isPaused: boolean;
  pausedTill: firebase.firestore.Timestamp;
  logInterval: number;
  isBacklightOn: boolean;
  rgbLight: RgbColor;
  lockCode?: string;
}

export enum RgbColor {
  SA_BLUE = 0,
  RED = 1,
  ORANGE = 2,
  YELLOW = 3,
  GREEN = 4,
  BLUE = 5,
  PURPLE = 6,
  BLACK = 7,
  WHITE = 8,
}
