import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, LogService } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE } from '@shared';

enum VerificationState {
  PENDING,
  SUCCESS,
  FAILED,
}

@Component({
  selector: 'sc-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['../mobile.scss', './email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit {
  currentState = VerificationState.PENDING;
  VerificationState = VerificationState;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private logService: LogService,
    translateService: TranslateService,
  ) {
    // This is a manual map from mobile since ngx-translate doesn't fall back on the language code
    // The incoming lang parameter will be whatever Firebase Auth determines the code should be based on the mobile OS language.
    // Similarly, what the default language is set to should be a key in the locales map in locale.pipe.ts
    let lang = DEFAULT_LOCALE;
    const langCode = this.route.snapshot.queryParams.lang ? this.route.snapshot.queryParams.lang.split('-')[0] : '';
    switch (langCode) {
      case 'de':
        lang = 'de';
        break;
      case 'en':
        lang = 'en-us';
        break;
      case 'es':
        lang = 'es-419';
        break;
      case 'fr':
        lang = 'fr';
        break;
      case 'ja':
        lang = 'ja';
        break;
      case 'nl':
        lang = 'nl';
        break;
      case 'zh':
        lang = 'zh-Hans';
        break;
    }
    translateService.setDefaultLang(lang);
  }

  async ngOnInit() {
    try {
      await this.authService.verifyEmail(this.route.snapshot.queryParams.oobCode);
      this.currentState = VerificationState.SUCCESS;
    } catch (error) {
      this.logService.error('Failed to verify email', error);
      this.currentState = VerificationState.FAILED;
    }
  }
}
