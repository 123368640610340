import { Component, EventEmitter, Input, Output } from '@angular/core';

import cloneDeep from 'lodash-es/cloneDeep';

export interface AggCount {
  name: string;
  count: number;
  filter?: string;
  selected?: boolean;
  type?: string;
}

@Component({
  selector: 'sc-aggregate',
  templateUrl: './aggregate.component.html',
  styleUrls: ['./aggregate.component.scss'],
})
export class AggregateComponent {
  @Input() filterable: boolean;
  @Output() filter: EventEmitter<string[]> = new EventEmitter<string[]>();

  _counts: AggCount[];

  get counts() {
    return cloneDeep(this._counts);
  }

  @Input() set counts(counts: AggCount[]) {
    let selectedChanged = false;

    if (this._counts && this.filterable) {
      counts.forEach((count) => {
        const prevCount = this._counts.find((x) => x.name === count.name);
        const wasSelected = prevCount.selected && count.count > 0;

        if (prevCount && count.selected !== wasSelected) {
          // eslint-disable-next-line no-param-reassign
          count.selected = wasSelected;
          selectedChanged = true;
        }
      });
    }
    this._counts = counts;

    if (selectedChanged) {
      setTimeout(() => this.emitFilter(), 0);
    }
  }

  updateFilter(count: AggCount) {
    if (!count.count) return;

    // eslint-disable-next-line no-param-reassign
    count.selected = !count.selected;
    this.emitFilter();
  }

  emitFilter() {
    const selected = this.counts.filter((x) => x.selected && x.count).map((x) => x.filter);
    this.filter.emit(selected);
  }
}
