import { NgModule } from '@angular/core';

import { DaoCollectionFactory, DaoFactory } from './dao';
import { PersistenceService } from './services';

@NgModule({
  imports: [],
  providers: [DaoFactory, DaoCollectionFactory, PersistenceService],
  exports: [],
})
export class DataModule {}
