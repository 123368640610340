import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DayOfWeek } from '@data';

interface DayItem {
  name: string;
  char: string;
  active: boolean;
}

@Component({
  selector: 'sc-week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeekPickerComponent),
      multi: true,
    },
  ],
})
export class WeekPickerComponent implements ControlValueAccessor {
  dayModel: DayItem[] = [
    { name: DayOfWeek.Sunday, char: 'S', active: false },
    { name: DayOfWeek.Monday, char: 'M', active: true },
    { name: DayOfWeek.Tuesday, char: 'T', active: true },
    { name: DayOfWeek.Wednesday, char: 'W', active: true },
    { name: DayOfWeek.Thursday, char: 'T', active: true },
    { name: DayOfWeek.Friday, char: 'F', active: true },
    { name: DayOfWeek.Saturday, char: 'S', active: false },
  ];

  /**
   * Write a new value to the element.
   */
  writeValue(obj: any): void {
    if (obj) {
      this.dayModel.forEach((day) => {
        // eslint-disable-next-line no-param-reassign
        day.active = obj.includes(day.name);
      });
    }
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   */
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _onChange(_value: string[]) {}

  updateDay(day: DayItem) {
    // eslint-disable-next-line no-param-reassign
    day.active = !day.active;
    const activeDays = this.dayModel.filter((d) => d.active).map((d) => d.name);
    this._onChange(activeDays);
  }
}
