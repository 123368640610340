import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const temp = new Map<string, string>([
  ['F', 'COMMON.FAHRENHEIT'],
  ['C', 'COMMON.CELSIUS'],
]);

// Set pure to false as result depends on set locale,
// which does not change input value, only the output.
@Pipe({ name: 'tempName', pure: false })
export class TempNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(code: string): string {
    return temp.has(code) ? this.translateService.instant(temp.get(code)) : code;
  }
}
