/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { LogLevel } from '../log-level.model';
import { LogMessage } from '../log-message.model';
import { LogAppender } from './log-appender.service';

const LOG_LEVEL_STYLES = new Map<LogLevel, string>();
LOG_LEVEL_STYLES[LogLevel.Trace] = 'font-weight: bold; color: #B0BEC5;';
LOG_LEVEL_STYLES[LogLevel.Debug] = 'font-weight: bold; color: #4CAF50;';
LOG_LEVEL_STYLES[LogLevel.Info] = 'font-weight: bold; color: #0277BD;';
LOG_LEVEL_STYLES[LogLevel.Warn] = 'font-weight: bold; color: #FFC107;';
LOG_LEVEL_STYLES[LogLevel.Error] = 'font-weight: bold; color: #B71C1C;';
LOG_LEVEL_STYLES[LogLevel.Fatal] = 'font-weight: bold; color: #B71C1C;';

@Injectable()
export class ConsoleAppender implements LogAppender {
  /**
   * Write a log message to the console.
   *
   * @param logMessage
   * @memberof ConsoleAppender
   */
  public write(logMessage: LogMessage): void {
    const method = this.getWriteMethod(logMessage.level);
    const style = LOG_LEVEL_STYLES[logMessage.level];

    if (logMessage.exception) {
      console.groupCollapsed(`${logMessage.timestamp.toLocaleString()} [${logMessage.levelText}] (${logMessage.zone})`);
      method.call(console, `%c${logMessage.message}`, style);
      if (logMessage.data != null) {
        method.call(console, logMessage.data);
      }
      if (logMessage.exception) {
        method.call(console, logMessage.exception);
      }
      console.groupEnd();
    } else {
      const message = `%c${logMessage.timestamp.toLocaleString()} [${logMessage.levelText}] (${logMessage.zone}) - %c${
        logMessage.message
      }`;
      if (logMessage.data != null) {
        method.call(console, message, style, '', logMessage.data);
      } else {
        method.call(console, message, style, '');
      }
    }
  }

  /**
   * Get the method to use for logging the message based on its level.
   *
   * @private
   * @param level
   * @memberof ConsoleAppender
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private getWriteMethod(level: LogLevel): (message?: any, ...optionalParams: any[]) => void {
    // favor formatting over filtering
    return console.log;
    /* 
        switch (level) {
            case LogLevel.Trace:
                return console.trace;
            case LogLevel.Debug:
                return console.debug;
            case LogLevel.Info:
                return console.info;
            case LogLevel.Warn:
                return console.warn;
            case LogLevel.Error:
            case LogLevel.Fatal:
                return console.error;
        }
        return null;
        */
  }
}
