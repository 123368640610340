import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AppRoute } from '@shared';

import { LogService, LogZone } from '../logging';
import { Claims, Permission, PermissionManager } from '../utils';
import { AuthService } from './auth.service';

@Injectable()
export class DashboardService {
  constructor(
    private authService: AuthService,
    private logService: LogService,
    private router: Router,
  ) {
    this.logService = this.logService.for(LogZone.AUTHENTICATION);
  }

  async goToDashboard(returnUrl?: string): Promise<boolean> {
    const token = await this.authService.getIdToken();
    if (!token) {
      this.authService.logout();
      return false;
    } else if (!token.claims) {
      this.router.navigateByUrl(AppRoute.AccountSetup);
      return true;
    }

    const claims = token.claims as Claims;
    if (claims.portal !== 'CUSTOMER') {
      this.logService.trace('User accessed incorrect portal. Logging out.');
      this.authService.logout();
      return false;
    } else if (PermissionManager.hasPermission(Permission.MANAGE_ADVERTISEMENT)) {
      this.router.navigateByUrl(AppRoute.Marketing);
      return true;
    } else if (PermissionManager.hasPermission(Permission.READ_ORGANIZATION_DIRECTORY)) {
      this.logService.trace('Redirecting internal user to the organizations dashboard.');
      this.router.navigateByUrl(returnUrl || AppRoute.Organizations);
      return true;
    } else if (claims.locId) {
      this.logService.trace('Redirecting location user to the location dashboard.');
      this.router.navigateByUrl(returnUrl || AppRoute.location(claims.orgId, claims.locId));
      return true;
    } else if (claims.orgId) {
      this.logService.trace('Redirecting organization user to the organization dashboard.');
      this.router.navigateByUrl(returnUrl || AppRoute.organization(claims.orgId));
      return true;
    } else {
      this.logService.error('Invalid claims: ', claims);
      this.router.navigateByUrl(AppRoute.NotFound);
      return false;
    }
  }
}
