import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { Observable, of } from 'rxjs';

@Pipe({
  name: 'cloudImage',
})
export class CloudImagePipe implements PipeTransform {
  constructor(private storage: AngularFireStorage) {}
  transform(imagePath: string): Observable<string> {
    if (imagePath == null) return of('');
    return this.storage.ref(imagePath).getDownloadURL();
  }
}
