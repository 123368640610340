import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { DashboardService } from '../services';
import { LocalStorageUtil } from '../utils';

// eslint-disable-next-line func-style
export const accountSetupGuard: CanActivateFn = () => {
  if (LocalStorageUtil.getClaims()) {
    inject(DashboardService).goToDashboard();
    return false;
  } else {
    return true;
  }
};
