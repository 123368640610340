import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { DashboardService } from '../services';
import { Permission, PermissionManager } from '../utils';

// eslint-disable-next-line func-style
export const permissionGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const permission = route.data.permission as Permission;

  if (PermissionManager.hasPermission(permission)) {
    return true;
  } else {
    inject(DashboardService).goToDashboard();
    inject(ToastrService).error(inject(TranslateService).instant('GUARDS.UNAUTHORIZED_ACCESS'));
    return false;
  }
};
