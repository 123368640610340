import { Pipe, PipeTransform } from '@angular/core';

import { AssetFirmwareStatus } from '@data';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateAssetFirmwareStatus',
  pure: false,
})
export class TranslateAssetFirmwareStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(status: AssetFirmwareStatus): any {
    return this.translateService.instant(this.getStatusKey(status));
  }

  // eslint-disable-next-line consistent-return
  private getStatusKey(status: AssetFirmwareStatus) {
    switch (status) {
      case AssetFirmwareStatus.Outdated:
        return 'FIRMWARE_STATUSES.OUTDATED';
      case AssetFirmwareStatus.Updated:
        return 'FIRMWARE_STATUSES.UPDATED';
      case AssetFirmwareStatus.Unknown:
        return 'FIRMWARE_STATUSES.UNKNOWN';
    }
  }
}
