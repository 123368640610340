import { InternalRole } from '../enums';

export class InternalUser {
  firstName = '';
  lastName = '';
  email = '';
  locale = '';
  temp = '';
  role: InternalRole;

  constructor(firstName: string, lastName: string, email: string, locale: string, temp: string, role: InternalRole) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.locale = locale;
    this.temp = temp;
    this.role = role;
  }

  asObject() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      locale: this.locale,
      temp: this.temp,
      role: this.role,
    };
  }
}
