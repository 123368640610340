import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';

import { SearchItemType, SearchResults, SearchResultsExtraTextType } from '../search.component';

@Component({
  selector: 'sc-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent {
  private _searchResults: SearchResults;
  @Input() set searchResults(searchResults: SearchResults) {
    this.selectedIndex = -1;
    this._searchResults = searchResults;
  }

  get searchResults() {
    return this._searchResults;
  }

  @Input() searchTerm: string;
  @Input() show: boolean;
  @Output() selectResult = new EventEmitter<void>();
  @Output() refocus = new EventEmitter<void>();
  @ViewChildren('result') results: QueryList<ElementRef>;

  SearchItemType = SearchItemType;
  SearchResultsExtraTextType = SearchResultsExtraTextType;

  selectedIndex = -1;

  constructor(private router: Router) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.show && this.searchResults.items.length > 0) {
      switch (event.code) {
        case 'ArrowUp':
          if (this.selectedIndex > -1) {
            this.selectedIndex--;
            if (this.selectedIndex > -1) {
              this.results.toArray()[this.selectedIndex].nativeElement.focus();
            } else {
              this.refocus.emit();
            }
          }
          break;
        case 'ArrowDown':
          if (this.selectedIndex < this.searchResults.items.length - 1) {
            this.selectedIndex++;
            this.results.toArray()[this.selectedIndex].nativeElement.focus();
          }
          break;
        case 'Enter':
          if (this.selectedIndex > -1) {
            this.resultSeleted(this.searchResults.items[this.selectedIndex].path);
          }
          break;
        default:
          break;
      }
    }
  }

  resultSeleted(path: string) {
    this.router.navigateByUrl(path);
    this.selectResult.emit();
  }
}
