import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentChangeAction } from '@angular/fire/compat/firestore';

export function mapCollectionWithId<T>(): OperatorFunction<DocumentChangeAction<T>[], Map<string, T>> {
  return map((actions) =>
    actions.reduce((m, action) => {
      m.set(action.payload.doc.id, action.payload.doc.data());
      return m;
    }, new Map<string, T>()),
  );
}
