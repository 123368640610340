import firebase from 'firebase/compat/app';
import { ToastrService } from 'ngx-toastr';

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, DashboardService, LocalStorageUtil, LogService } from '@core';
import { AppRoute } from '@shared';

enum ErrorCode {
  invalidEmail = 'auth/invalid-email',
  expiredCode = 'auth/invalid-action-code',
  malformedURL = 'auth/argument-error',
}

@Component({
  selector: 'sc-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.scss', '../auth.scss'],
})
export class CreatePasswordComponent {
  email = LocalStorageUtil.getEmail();
  isInvalidEmail = false;
  isSubmitted = false;
  isEmailVerified: boolean;
  password = '';
  confirmPassword = '';
  invalidMatch = false;
  invalidLength = false;
  loading = false;
  readonly reqPasswordLength = 8;

  constructor(
    private logService: LogService,
    private toastService: ToastrService,
    private authService: AuthService,
    private router: Router,
    private dashboardService: DashboardService,
  ) {
    const user = firebase.auth().currentUser;
    if (user) {
      this.isEmailVerified = true;
      this.email = user.email;
      LocalStorageUtil.removeEmail();
    }
  }

  async verifyEmail() {
    try {
      this.isInvalidEmail = false;
      await firebase.auth().signInWithEmailLink(this.email, window.location.href);
      this.isEmailVerified = true;
      LocalStorageUtil.removeEmail();
    } catch (error) {
      this.logService.error('Failed to sign in with email link', error);
      switch (error.code) {
        case ErrorCode.invalidEmail:
          this.isInvalidEmail = true;
          break;
        case ErrorCode.expiredCode:
        case ErrorCode.malformedURL:
          this.toastService.error('Link is invalid or has expired');
          this.router.navigateByUrl(AppRoute.Login);
          break;
        default:
          this.toastService.error('An unexpected error has occured.');
          break;
      }
    }
  }

  async createPassword() {
    this.invalidMatch = this.password !== this.confirmPassword;
    this.invalidLength = !this.invalidMatch && this.password.length < this.reqPasswordLength;
    if (this.invalidMatch || this.invalidLength) {
      return;
    }

    this.loading = true;
    this.logService.debug('Setting user password...', { email: this.email });

    const user = this.authService.getCurrentUser();
    try {
      await user.updatePassword(this.password);
      this.dashboardService.goToDashboard();
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        this.toastService.error('This action requires a recent login, please sign-up again');
        this.authService.logout();
        this.router.navigateByUrl(AppRoute.Login);
      } else {
        this.toastService.error('Failed to update password');
        this.logService.error('Failed to update password', error);
      }
    }
    this.loading = false;
  }
}
