export class Path {
  public static internalUsers(): string {
    return `internalUsers`;
  }

  public static internalUser(userId: string): string {
    return `${this.internalUsers()}/${userId}`;
  }

  public static unclaimedAssets(): string {
    return `unclaimedAssets`;
  }

  public static organizations(): string {
    return `organizations`;
  }

  public static organization(organizationId: string): string {
    return `${this.organizations()}/${organizationId}`;
  }

  public static orgUsers(orgId: string): string {
    return `${this.organization(orgId)}/users`;
  }

  public static orgUser(orgId: string, userId: string): string {
    return `${this.orgUsers(orgId)}/${userId}`;
  }

  public static locationsCollectionGroup(): string {
    return 'locations';
  }

  public static locations(orgId: string): string {
    return `${this.organization(orgId)}/locations`;
  }

  public static location(orgId: string, locationId: string): string {
    return `${this.locations(orgId)}/${locationId}`;
  }

  public static schedules(orgId: string, locationId: string): string {
    return `${this.location(orgId, locationId)}/schedules`;
  }

  public static schedule(orgId: string, locationId: string, schId: string): string {
    return `${this.schedules(orgId, locationId)}/${schId}`;
  }

  public static locUsers(orgId: string, locId: string): string {
    return `${this.location(orgId, locId)}/users`;
  }

  public static locUser(orgId: string, locId: string, userId: string): string {
    return `${this.locUsers(orgId, locId)}/${userId}`;
  }

  public static assets(orgId: string, locationId: string): string {
    return `${this.location(orgId, locationId)}/assets`;
  }

  public static asset(orgId: string, locationId: string, assetId: string): string {
    return `${this.assets(orgId, locationId)}/${assetId}`;
  }

  public static homeUsers(): string {
    return `homeUsers`;
  }

  public static homeUser(userId: string): string {
    return `${this.homeUsers()}/${userId}`;
  }

  public static assetTypes(): string {
    return `assetTypes`;
  }

  public static assetType(assetTypeId: string): string {
    return `${this.assetTypes()}/${assetTypeId}`;
  }

  public static getPath(path: string, segCount: number): string {
    return path.split('/').slice(0, segCount).join('/');
  }

  public static settings(): string {
    return 'settings';
  }

  public static setting(setting: SettingsPathSegment) {
    return `${Path.settings()}/${setting}`;
  }

  public static scheduledAds() {
    return `${Path.setting(SettingsPathSegment.MobileListAdvertisement)}/scheduledAds`;
  }
}

export enum SettingsPathSegment {
  MobileListAdvertisement = 'mobileListAdvertisement',
}
