import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AssetService {
  constructor(
    private firebaseFunctions: AngularFireFunctions,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}

  async claimAsset(params: { name: string; mac: string; pin: string; locPath: string }) {
    const claim = this.firebaseFunctions.httpsCallable('claimAsset');
    return firstValueFrom(claim(params));
  }

  async unclaimAsset(params: { mac: string; locPath: string }) {
    const unclaim = this.firebaseFunctions.httpsCallable('unclaimAsset');
    return firstValueFrom(unclaim(params));
  }

  async updateFirmware(mac: string) {
    const update = this.firebaseFunctions.httpsCallable('updateFirmware');
    return firstValueFrom(update({ mac }));
  }
}
