import firebase from 'firebase/compat/app';

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService, LogService } from '@core';

@Component({
  selector: 'sc-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['../auth.scss'],
})
export class SignupComponent implements OnInit {
  email: FormControl;
  invalid = false;
  loading = false;
  emailNotSent = true;
  isEmailInUse = false;

  constructor(
    private authService: AuthService,
    private logService: LogService,
  ) {}

  ngOnInit() {
    this.email = new FormControl('', { validators: [Validators.required, Validators.email] });
  }

  async signup() {
    this.loading = true;
    this.logService.debug('Authenticating user ', { email: this.email.value });
    try {
      const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(this.email.value);
      this.isEmailInUse = signInMethods.includes(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD);
    } catch (error) {
      this.logService.error(error);
      return;
    }
    if (this.email.invalid || this.isEmailInUse) {
      this.loading = false;
      this.invalid = this.email.invalid;
      return;
    }
    try {
      await this.authService.emailSignup(this.email.value);
      this.logService.info('User authenticated successfully.', { email: this.email.value });

      this.emailNotSent = false;
    } catch (error) {
      this.logService.error('Unable to authenticate user ', { error });
      this.invalid = true;
      this.loading = false;
    }
  }
}
