import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { firstValueFrom } from 'rxjs';

export interface GlobalSearchResponse {
  orgs: EsOrganization[];
  locs: EsLocation[];
  assets: EsAsset[];
}

interface EsOrganization {
  name: string;
  id: string;
  fsId: string;
  score: number;
}

interface EsLocation {
  name: string;
  fsId: string;
  fsOrganizationId: string;
  score: number;
}

interface EsAsset {
  mac: string;
  name: string;
  fsLocationId: string;
  fsOrganizationId: string;
  score: number;
}

export interface UserSearchResponse {
  users: EsUser[];
}

interface EsUser {
  displayName: string;
  email: string;
  fsOrganizationId: string;
  score: number;
}

@Injectable()
export class ElasticsearchService {
  constructor(private firebaseFunctions: AngularFireFunctions) {}

  async prepareSearch(): Promise<void> {
    const globalCallable = this.firebaseFunctions.httpsCallable('searchElasticsearch');
    await firstValueFrom(globalCallable({ ping: true }));
    const usersCallable = this.firebaseFunctions.httpsCallable('enterpriseUserSearch');
    await firstValueFrom(usersCallable({ ping: true }));
  }

  searchGlobal(query: string): Promise<GlobalSearchResponse> {
    const callable = this.firebaseFunctions.httpsCallable('searchElasticsearch');
    return firstValueFrom(callable({ query }));
  }

  searchUsers(query: string): Promise<UserSearchResponse> {
    const callable = this.firebaseFunctions.httpsCallable('enterpriseUserSearch');
    return firstValueFrom(callable({ query }));
  }
}
