import 'firebase/compat/firestore';

import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, ORIGIN } from '@angular/fire/compat/functions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'environments/environment';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ToastrModule } from 'ngx-toastr';

import { EnvironmentConfig } from '@env/environment-config';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth';
import { CoreModule, LogService } from './core';
import { MyMissingTranslationHandler } from './core/utils/missing-translation-handler.util';
import { DataModule } from './data';
import { MobileModule } from './mobile/mobile.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    BrowserModule,
    CommonModule,
    NgxSliderModule,
    CoreModule,
    DataModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
        deps: [LogService],
      },
    }),
    MobileModule,
  ],
  providers: [
    { provide: EnvironmentConfig, useValue: environment },
    {
      provide: SETTINGS,
      useValue: environment.emulateFirestore
        ? {
            host: 'localhost:8080',
            ssl: false,
          }
        : undefined,
    },
    { provide: ORIGIN, useValue: environment.emulateFunctions ? 'http://localhost:5001' : undefined },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
