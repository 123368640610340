import { Observable, of, timer } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { AssetFirmwareStatus, AssetStatus, getHardwareProperties } from '../enums';
import { Asset, AssetConfig } from '../models';
import { PersistenceService } from '../services/persistence.service';
import { Path } from './path.util';

export function getStatus(asset: Asset): AssetStatus {
  if (!asset.state) {
    const { hasBluetooth } = getHardwareProperties(asset.calibration.hardware);
    if (hasBluetooth) return AssetStatus.BluetoothOnly;
    else return AssetStatus.Unavailable;
  }
  if (asset.state.isOff) {
    return AssetStatus.Off;
  } else if (!asset.isOnline) {
    return AssetStatus.Offline;
  } else if (isPaused(asset.config)) {
    return AssetStatus.PausedManual;
  } else if (asset.state.isDuctOff && !asset.state.ductOverride) {
    return AssetStatus.PausedInterrupt;
  } else if (asset.state.isInEvent) {
    return AssetStatus.Active;
  } else {
    return AssetStatus.Idle;
  }
}

export async function getFirmwareStatus(
  asset: Asset,
  persistenceService: PersistenceService,
): Promise<AssetFirmwareStatus> {
  if (getHardwareProperties(asset.calibration.hardware).isLinkLabsHardware) return AssetFirmwareStatus.Updated;
  if (!asset.state?.firmwareVersion) return AssetFirmwareStatus.Unknown;
  const { latestFirmwareVersion } = (
    await persistenceService.getDoc(Path.assetType(asset.calibration.hardware)).get()
  ).data()!;

  return asset.state.firmwareVersion !== latestFirmwareVersion
    ? AssetFirmwareStatus.Outdated
    : AssetFirmwareStatus.Updated;
}

export function isPaused(config: AssetConfig): boolean {
  return config.isPaused && (config.pausedTill == null || Date.now() < config.pausedTill.toMillis());
}

export function msTillExpiration(config: AssetConfig): number {
  if (!isPaused(config) || config.pausedTill == null) return 0;
  return config.pausedTill ? config.pausedTill.toMillis() - Date.now() : 0;
}

export function assetStreamWithResumeTrigger(asset: Observable<Asset>): Observable<Asset> {
  return asset.pipe(switchMap((a) => assetWithResumeTrigger(a.config, a)));
}

export function assetWithResumeTrigger<T>(config: AssetConfig, item: T): Observable<T> {
  if (!isPaused(config) || config.pausedTill == null) return of(item);
  return timer(msTillExpiration(config)).pipe(
    switchMap(() => of(item)),
    startWith(item),
  );
}
