import { Observable } from 'rxjs';

import { Organization } from '../models';
import { BaseDAO } from './base.dao';
import { UserDAO } from './user.dao';
import { LocationDAO } from './location.dao';

export class OrganizationDAO extends BaseDAO<Organization> {
  private _users: Observable<UserDAO[]>;
  private _locations: Observable<LocationDAO[]>;

  public get users(): Observable<UserDAO[]> {
    if (this._users == null) {
      const path = `${this.path}/users`;
      this._users = this.initCollection(UserDAO, path);
    }
    return this._users;
  }

  public get locations(): Observable<LocationDAO[]> {
    if (this._locations == null) {
      const path = `${this.path}/locations`;
      this._locations = this.initCollection(LocationDAO, path);
    }
    return this._locations;
  }
}
