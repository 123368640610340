import { AuthService, Role } from '../services/auth.service';

export enum Permission {
  READ_INTERNAL_USER_DIRECTORY,
  READ_EXTERNAL_USER_DIRECTORY,
  READ_ORGANIZATION_DIRECTORY,
  WRITE_ASSET_CONFIG,
  READ_ASSET_TELEMETRY,
  WRITE_ASSET_TELEMETRY_INTERVAL,
  WRITE_ASSET_SCHEDULE,
  WRITE_ASSET_STREAM_PUMP,
  CREATE_ASSET,
  CREATE_LORA_ASSET,
  UPDATE_ASSET,
  UPDATE_ASSET_FIRMWARE,
  DELETE_ASSET,
  WRITE_LOCATION,
  WRITE_ORGANIZATION,
  MANAGE_ADVERTISEMENT,
  MANAGE_SCENTSECURE,
}

const permissionMap: Map<number, string[]> = new Map([
  [Permission.READ_INTERNAL_USER_DIRECTORY, [Role.Admin]],
  [Permission.READ_EXTERNAL_USER_DIRECTORY, [Role.Admin, Role.Manager]],
  [Permission.READ_ORGANIZATION_DIRECTORY, [Role.Admin, Role.DeviceManager]],
  [Permission.WRITE_ASSET_CONFIG, [Role.Admin, Role.Manager, Role.Operator]],
  [Permission.READ_ASSET_TELEMETRY, [Role.Admin, Role.DeviceManager]],
  [Permission.WRITE_ASSET_TELEMETRY_INTERVAL, [Role.Admin]],
  [Permission.WRITE_ASSET_SCHEDULE, [Role.Admin, Role.Manager, Role.Operator]],
  [Permission.WRITE_ASSET_STREAM_PUMP, [Role.Admin]],
  [Permission.CREATE_ASSET, [Role.Admin, Role.Manager, Role.DeviceManager]],
  [Permission.CREATE_LORA_ASSET, [Role.Admin, Role.DeviceManager]],
  [Permission.UPDATE_ASSET, [Role.Admin, Role.Manager, Role.Operator]],
  [Permission.UPDATE_ASSET_FIRMWARE, [Role.Admin, Role.DeviceManager, Role.Manager, Role.Operator]],
  [Permission.DELETE_ASSET, [Role.Admin, Role.Manager, Role.DeviceManager]],
  [Permission.WRITE_LOCATION, [Role.Admin, Role.Manager]],
  [Permission.WRITE_ORGANIZATION, [Role.Admin]],
  [Permission.MANAGE_ADVERTISEMENT, [Role.Marketing]],
  [Permission.MANAGE_SCENTSECURE, [Role.Manager, Role.Admin]],
]);

export class PermissionManager {
  static hasPermission(permission: Permission): boolean {
    return permissionMap.get(permission).includes(AuthService.getRole());
  }
}
