import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PaginatorService {
  $currentPage: BehaviorSubject<number> = new BehaviorSubject(1);

  setPage(page: number) {
    this.$currentPage.next(page);
  }
}
