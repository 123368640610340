import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

/**
 * Add processing indication to a button.
 * Centralized in a directive rather than using ngClass
 * so that we can change the appearance later.
 *
 * @export
 * @class BtnProgressDirective
 * @implements {OnChanges}
 */
@Directive({
  selector: '[scBtnProcessing]',
})
export class BtnProcessingDirective implements OnChanges {
  @Input('scBtnProcessing')
  public isProcessing: boolean;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    if (this.isProcessing) {
      this.startProcessing();
    } else {
      this.stopProcessing();
    }
  }

  private startProcessing(): void {
    this.el.nativeElement.disabled = true;
    this.el.nativeElement.classList.add('btn-loading');
  }

  private stopProcessing(): void {
    this.el.nativeElement.classList.remove('btn-loading');
    this.el.nativeElement.disabled = false;
  }
}
