import { Component, Input } from '@angular/core';

export class Breadcrumb {
  name: string;
  target?: string;
}

@Component({
  selector: 'sc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() trail: Breadcrumb[];
}
