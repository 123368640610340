enum LocalStorageKeys {
  CLAIMS = 'claims',
  EMAIL = 'email',
  LOCALE = 'locale',
}

export interface Claims {
  portal: string;
  role: string;
  scope: string; // only for external
  orgId: string; // only for external
  locId: string; // only for external
}

export class LocalStorageUtil {
  static getLocale(): string {
    return localStorage.getItem(LocalStorageKeys.LOCALE);
  }

  static setLocale(locale: string) {
    localStorage.setItem(LocalStorageKeys.LOCALE, locale);
  }

  static getClaims(): Claims {
    const unparsedClaims = localStorage.getItem(LocalStorageKeys.CLAIMS);
    return unparsedClaims ? JSON.parse(unparsedClaims) : null;
  }

  static setClaims(claims: Claims) {
    localStorage.setItem(LocalStorageKeys.CLAIMS, JSON.stringify(claims));
  }

  static removeClaims() {
    localStorage.removeItem(LocalStorageKeys.CLAIMS);
  }

  static getEmail(): string {
    return localStorage.getItem(LocalStorageKeys.EMAIL);
  }

  static setEmail(email: string) {
    localStorage.setItem(LocalStorageKeys.EMAIL, email);
  }

  static removeEmail() {
    localStorage.removeItem(LocalStorageKeys.EMAIL);
  }
}
