import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanMatchFn, Router } from '@angular/router';

import { first, map } from 'rxjs/operators';

import { AppRoute } from '@shared';

export const RETURN_URL_KEY = 'returnUrl';

/**
 * Only allow access to the guarded route to users who are authenticated.
 */
// eslint-disable-next-line func-style
export const authenticationGuard: CanMatchFn = () => {
  const router = inject(Router);
  return inject(AngularFireAuth).user.pipe(
    first(),
    map((user) => {
      if (user) {
        return true;
      }

      const queryParams = {};
      queryParams[RETURN_URL_KEY] = window.location.pathname;
      router.navigate([AppRoute.Login], {
        queryParams,
      });

      return false;
    }),
  );
};
