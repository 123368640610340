import { LogLevel } from '../app/core/logging';

export class EnvironmentConfig {
  public name: string;
  public production: boolean;
  public emulateFirestore: boolean;
  public emulateFunctions: boolean;
  public firebase: FirebaseConfig;
  public logging: LoggingConfig;
}

export class FirebaseConfig {
  public apiKey: string;
  public authDomain: string;
  public databaseURL: string;
  public projectId: string;
  public storageBucket: string;
  public messagingSenderId: string;
  public measurementId: string;
}

export class LoggingConfig {
  public logLevel: LogLevel;
  public zones: Set<string>;
}
