import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sc-search-modal',
  templateUrl: './search-modal.component.html',
})
export class SearchModalComponent {
  constructor(public modal: NgbActiveModal) {}
}
