import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared';

import { CreatePasswordComponent } from './create-password/create-password.component';
import { ForgotPasswordComponent } from './forgot-password';
import { LayoutComponent } from './layout';
import { LoginComponent } from './login';
import { ResetPasswordComponent } from './reset-password';
import { SignupComponent } from './signup/signup.component';
import { UnauthenticatedComponent } from './unauthenticated/unauthenticated.component';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, SharedModule, ReactiveFormsModule],
  declarations: [
    LayoutComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    UnauthenticatedComponent,
    SignupComponent,
    CreatePasswordComponent,
  ],
  providers: [],
  exports: [],
})
export class AuthModule {}
