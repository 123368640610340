import { Component, Input } from '@angular/core';

export class HeaderTab {
  name: string;
  target?: string;
}

@Component({
  selector: 'sc-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
})
export class HeaderTabsComponent {
  @Input() tabs: HeaderTab[];
}
