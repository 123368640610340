import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import firebase from 'firebase/compat/app';
import { first, switchMap } from 'rxjs/operators';

import { AppRoute } from '@shared';

import { LogService } from '../logging';
import { AuthService, DashboardService } from '../services';
import { LocalStorageUtil } from '../utils';

/**
 * If the user attempts to go to the login page then redirect them to their dashboard.
 */
// eslint-disable-next-line func-style
export const loginGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const logService = inject(LogService);
  const dashboardService = inject(DashboardService);

  return inject(AngularFireAuth).user.pipe(
    first(),
    switchMap(async (user) => {
      const path = `/${route.url.join('')}`;
      const currentUrl = window.location.origin + state.url;
      if (path === AppRoute.SetPassword && !firebase.auth().isSignInWithEmailLink(currentUrl)) {
        router.navigate([AppRoute.Login]);
        return false;
      } else if (!user) {
        return true;
      }

      try {
        if (!(await AuthService.hasPassword(user))) {
          if (path === AppRoute.SetPassword) {
            return true;
          } else {
            router.navigate([AppRoute.SetPassword]);
            return false;
          }
        }
      } catch (error) {
        logService.error('Failed to check authentication method', error);
        return false;
      }
      const claims = LocalStorageUtil.getClaims();
      if (path !== AppRoute.SetPassword && claims && claims.role == null) {
        return true;
      } else {
        dashboardService.goToDashboard();
        return false;
      }
    }),
  );
};
