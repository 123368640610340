import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { DashboardService, Scope } from '../services';
import { LocalStorageUtil, Permission, PermissionManager } from '../utils';

// eslint-disable-next-line func-style
export const orgIdGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const claims = LocalStorageUtil.getClaims();

  if (
    PermissionManager.hasPermission(Permission.READ_ORGANIZATION_DIRECTORY) ||
    (claims.orgId === route.params.orgId && claims.scope === Scope.Organization)
  ) {
    return true;
  } else {
    inject(DashboardService).goToDashboard();
    inject(ToastrService).error(inject(TranslateService).instant('GUARDS.UNAUTHORIZED_ACCESS'));
    return false;
  }
};
