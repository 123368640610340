export const LOG_ZONE = 'LOG_ZONE';

export const LogZone = {
  GLOBAL: 'Global',
  AUTHENTICATION: 'Authentication',
  FIRESTORE: 'Firestore',
  ORGANIZATIONS: 'Organizations',
  ORGANIZATION: 'Organization',
  LOCATION: 'Location',
  ASSET: 'Asset',
  USERS: 'Users',
  USER: 'User',
  MARKETING: 'Marketing',
};
