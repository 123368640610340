export enum AssetStatus {
  Active = 'Active',
  Idle = 'Idle',
  Offline = 'Offline',
  Off = 'Off',
  PausedManual = 'Paused (Manual Override)',
  PausedInterrupt = 'Paused (Duct Interrupt)',
  BluetoothOnly = 'Bluetooth Only',
  Unavailable = 'Unavailable',
}

export enum AssetFirmwareStatus {
  Outdated = 'Outdated',
  Updated = 'Updated',
  Unknown = 'Unknown',
}
