import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { Asset } from '../models';
import { assetWithResumeTrigger } from '../utils';
import { BaseDAO } from './base.dao';

export class AssetDAO extends BaseDAO<Asset> {
  public get stream(): Observable<Asset> {
    return super.stream.pipe(
      switchMap((asset) => assetWithResumeTrigger(asset.config, asset)),
      shareReplay(1),
    );
  }
}
