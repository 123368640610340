import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const DEFAULT_LOCALE = 'en-us';

export const locales = new Map<string, LanguageProperties>([
  ['de', { i18nKey: 'LOCALE.GERMAN', nativeI18nKey: 'LOCALE.NATIVE.GERMAN' }],
  ['en-us', { i18nKey: 'LOCALE.ENGLISH', nativeI18nKey: 'LOCALE.NATIVE.ENGLISH' }],
  ['es-419', { i18nKey: 'LOCALE.SPANISH', nativeI18nKey: 'LOCALE.NATIVE.SPANISH' }],
  ['fr', { i18nKey: 'LOCALE.FRENCH', nativeI18nKey: 'LOCALE.NATIVE.FRENCH' }],
  ['ja', { i18nKey: 'LOCALE.JAPANESE', nativeI18nKey: 'LOCALE.NATIVE.JAPANESE' }],
  ['nl', { i18nKey: 'LOCALE.DUTCH', nativeI18nKey: 'LOCALE.NATIVE.DUTCH' }],
  ['zh-Hans', { i18nKey: 'LOCALE.CHINESE', nativeI18nKey: 'LOCALE.NATIVE.CHINESE' }],
]);

interface LanguageProperties {
  i18nKey: string;
  nativeI18nKey: string;
}

@Pipe({ name: 'locale', pure: false })
export class LocalePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(code: string): string {
    return locales.has(code) ? this.translateService.instant(locales.get(code).i18nKey) : code;
  }
}
