import { ChangeDetectorRef, Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import firebase from 'firebase/compat/app';
import { auth as authui } from 'firebaseui';
import { firstValueFrom } from 'rxjs';

import { LogService } from '@core';
import { Path } from '@data';

@Component({
  selector: 'sc-account-linking',
  templateUrl: './account-linking.component.html',
  styleUrls: ['../mobile.scss', './account-linking.component.scss'],
})
export class AccountLinkingComponent {
  ui: authui.AuthUI;
  redirectUrl: URL;
  accountLinkingState: string;
  clientId: string;
  noAccount = false;
  signinFailed = false;
  showPassword = false;

  emailControl = new FormControl('', [Validators.required, Validators.email]);
  passwordControl = new FormControl('', [Validators.required]);
  form = new FormGroup({ email: this.emailControl, password: this.passwordControl });

  uiConfig: authui.Config = {
    signInOptions: [
      { provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID, fullLabel: 'Continue with Google' },
      { provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID, fullLabel: 'Continue with Facebook' },
      { provider: 'apple.com', scopes: ['email', 'name'], fullLabel: 'Continue with Apple' },
    ],
    signInFlow: 'popup',
    callbacks: {
      signInSuccessWithAuthResult: (result: firebase.auth.UserCredential) => {
        this.signinFailed = false;
        this.noAccount = false;
        // The result has a JWT in it, but it's from the auth provider, not Firebase
        this.redirectOauth(result.user.uid);
        return false;
      },
      signInFailure: async (error: authui.AuthUIError) => {
        this.log.warn(`Failure to sign in the user: ${error.code} - ${error.message}`);
        this.signinFailed = true;
      },
    },
  };

  constructor(
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private afa: AngularFireAuth,
    private afs: AngularFirestore,
    private log: LogService,
  ) {
    const { queryParams } = this.route.snapshot;
    this.redirectUrl = new URL(queryParams.redirect_uri);
    this.accountLinkingState = queryParams.state;
    this.clientId = queryParams.client_id;

    this.ui = new authui.AuthUI(firebase.auth());
    this.ui.start('#firebaseui-auth-container', this.uiConfig);
  }

  async submitLogin() {
    this.signinFailed = false;
    this.noAccount = false;

    let cred: firebase.auth.UserCredential;
    try {
      cred = await this.afa.signInWithEmailAndPassword(this.emailControl.value, this.passwordControl.value);
    } catch (e) {
      if (e.code) {
        switch (e.code) {
          case 'auth/invalid-email':
          case 'auth/user-not-found':
          case 'auth/wrong-password':
            this.noAccount = true;
            break;
          case 'auth/user-disabled':
          default:
            this.signinFailed = true;
            break;
        }
        this.log.info(`Failure to login: ${e.code}`, {}, e);
      } else {
        this.log.info('Failure to login', {}, e);
        this.signinFailed = true;
      }
      return;
    }
    this.redirectOauth(cred.user.uid);
  }

  async redirectOauth(uid: string) {
    const idToken = await (await this.afa.currentUser).getIdToken(false);
    const doc = await firstValueFrom(this.afs.doc(Path.homeUser(uid)).get());
    await this.afa.signOut();

    if (doc.exists) {
      this.redirectUrl.searchParams.append('code', idToken);
      this.redirectUrl.searchParams.append('state', this.accountLinkingState);
      window.location.href = this.redirectUrl.toString();
    } else {
      this.noAccount = true;
      this.ui.reset();
      this.ui.start('#firebaseui-auth-container', this.uiConfig);
      this.cdRef.detectChanges();
    }
  }
}
