import { Pipe, PipeTransform } from '@angular/core';

import { AssetStatus } from '@data';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateAssetStatus',
  pure: false,
})
export class TranslateAssetStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(status: AssetStatus): any {
    return this.translateService.instant(this.getStatusKey(status));
  }

  // eslint-disable-next-line consistent-return
  private getStatusKey(status: AssetStatus) {
    switch (status) {
      case AssetStatus.Active:
        return 'STATUSES.ACTIVE';
      case AssetStatus.Idle:
        return 'STATUSES.IDLE';
      case AssetStatus.Offline:
      case AssetStatus.Off:
        return 'COMMON.DISCONNECTED';
      case AssetStatus.PausedManual:
        return 'STATUSES.PAUSED_MANUAL_OVERRIDE';
      case AssetStatus.PausedInterrupt:
        return 'STATUSES.PAUSED_DUCT_INTERRUPT';
      case AssetStatus.BluetoothOnly:
        return 'STATUSES.BLUETOOTH_ONLY';
      case AssetStatus.Unavailable:
        return 'STATUSES.UNAVAILABLE';
    }
  }
}
