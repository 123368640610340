import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { Permission, PermissionManager } from '../../core/utils';
import { AppRoute } from '../constants';
import { HeaderTab } from './header-tabs.component';

export enum Tab {
  ORGANIZATIONS,
  LOCATIONS,
  ASSETS,
  SCHEDULES,
  USERS,
}

export class TabUtil {
  static createOrganizationDirectoryTabs(activeTab: Tab, translateService: TranslateService): Observable<HeaderTab[]> {
    return translateService.stream(['ORGANIZATION.ORGANIZATIONS', 'COMMON.USERS']).pipe(
      map((trans) => {
        const tabs: HeaderTab[] = [{ name: trans['ORGANIZATION.ORGANIZATIONS'], target: AppRoute.Organizations }];
        if (PermissionManager.hasPermission(Permission.READ_INTERNAL_USER_DIRECTORY)) {
          tabs.push({ name: trans['COMMON.USERS'], target: AppRoute.Users });
        }
        switch (activeTab) {
          case Tab.ORGANIZATIONS:
            delete tabs[0].target;
            break;
          case Tab.USERS:
            delete tabs[1].target;
            break;
          case Tab.ASSETS:
          case Tab.LOCATIONS:
          case Tab.SCHEDULES:
            throw new Error('Not a valid tab');
        }
        return tabs;
      }),
    );
  }

  static createOrganizationTabs(
    orgId: string,
    activeTab: Tab,
    translateService: TranslateService,
  ): Observable<HeaderTab[]> {
    return translateService.stream(['COMMON.LOCATIONS', 'COMMON.USERS']).pipe(
      map((trans) => {
        const tabs: HeaderTab[] = [{ name: trans['COMMON.LOCATIONS'], target: AppRoute.organization(orgId) }];
        if (PermissionManager.hasPermission(Permission.READ_EXTERNAL_USER_DIRECTORY)) {
          tabs.push({ name: trans['COMMON.USERS'], target: AppRoute.organizationUsers(orgId) });
        }
        switch (activeTab) {
          case Tab.LOCATIONS:
            delete tabs[0].target;
            break;
          case Tab.USERS:
            delete tabs[1].target;
            break;
          case Tab.ASSETS:
          case Tab.ORGANIZATIONS:
          case Tab.SCHEDULES:
            throw new Error('Not a valid tab');
        }
        return tabs;
      }),
    );
  }

  static createLocationTabs(
    orgId: string,
    locId: string,
    activeTab: Tab,
    translateService: TranslateService,
  ): Observable<HeaderTab[]> {
    return translateService.stream(['COMMON.ASSETS', 'SCHEDULE.SCHEDULES', 'COMMON.USERS']).pipe(
      map((trans) => {
        const tabs: HeaderTab[] = [
          { name: trans['COMMON.ASSETS'], target: AppRoute.location(orgId, locId) },
          { name: trans['SCHEDULE.SCHEDULES'], target: AppRoute.locationSchedules(orgId, locId) },
        ];
        if (PermissionManager.hasPermission(Permission.READ_EXTERNAL_USER_DIRECTORY)) {
          tabs.push({ name: trans['COMMON.USERS'], target: AppRoute.locationUsers(orgId, locId) });
        }
        switch (activeTab) {
          case Tab.ASSETS:
            delete tabs[0].target;
            break;
          case Tab.SCHEDULES:
            delete tabs[1].target;
            break;
          case Tab.USERS:
            delete tabs[2].target;
            break;
          case Tab.LOCATIONS:
          case Tab.ORGANIZATIONS:
            throw new Error('Not a valid tab');
        }
        return tabs;
      }),
    );
  }
}
