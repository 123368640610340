import moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'datetime' })
export class DatetimePipe implements PipeTransform {
  transform(value: moment.MomentInput, timezone: string, meridian: boolean, displaySeconds = true): string {
    const format = `MMM DD, YYYY ${meridian ? 'h' : 'HH'}:mm${displaySeconds ? ':ss' : ''}${meridian ? ' A' : ''} z`;
    return moment(value)
      .tz(timezone || moment.tz.guess())
      .format(format);
  }
}
