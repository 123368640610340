import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { AppRoute } from '@shared';

/**
 * Handles routing for Firebase Auth actions.
 */
// eslint-disable-next-line func-style
export const firebaseGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  switch (route.queryParams.mode) {
    case 'resetPassword':
      router.navigate([AppRoute.ResetPassword], { queryParams: { ...route.queryParams } });
      return false;
    case 'signIn':
      if (inject(AngularFireAuth).isSignInWithEmailLink(window.location.href)) {
        router.navigate([AppRoute.SetPassword], { queryParams: { ...route.queryParams } });
      }
      return false;
    case 'verifyEmail':
      router.navigate([AppRoute.MobileVerification], { queryParams: { ...route.queryParams } });
      return false;
    default:
      router.navigateByUrl(AppRoute.Login);
      return false;
  }
};
