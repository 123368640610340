import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import firebase from 'firebase/compat/app';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { Claims } from '@core';
import { AssetMeta, DaoFactory, Path, User, UserDAO } from '@data';
import { EnvironmentConfig } from '@env/environment-config';

import { LogService, LogZone } from '../logging';
import { LocalStorageUtil } from '../utils/local-storage.util';

// copy of role from @data. Can't use that one here due to circular dependency
export enum Role {
  Viewer = 'ENT_VIEWER',
  Operator = 'ENT_OPERATOR',
  Manager = 'ENT_MANAGER',
  Admin = 'ENT_ADMIN',
  DeviceManager = 'ENT_DEVICE_MANAGER',
  Marketing = 'ENT_MARKETING',
}

export enum Scope {
  Location = 'LOC',
  Organization = 'ORG',
}

@Injectable()
export class AuthService {
  private readonly _user: Observable<User>;
  public get user(): Observable<User> {
    return this._user;
  }

  constructor(
    private environment: EnvironmentConfig,
    private firebaseAuth: AngularFireAuth,
    private daoFactory: DaoFactory,
    private logService: LogService,
  ) {
    this.logService = this.logService.for(LogZone.AUTHENTICATION);
    this._user = this.initUser();
  }

  static getRole(): Role {
    const claims = LocalStorageUtil.getClaims();
    return claims ? (claims.role as Role) : Role.Viewer;
  }

  static getScope(): Scope {
    const claims = LocalStorageUtil.getClaims();
    return claims && claims.scope ? (claims.scope as Scope) : null;
  }

  static async hasPassword(user: firebase.User) {
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(user.email);
    return signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1;
  }

  /**
   * Get the decoded ID JWT or null if it doesn't exist.
   */
  async getIdToken(): Promise<any> {
    try {
      const idToken = await (await this.firebaseAuth.currentUser).getIdTokenResult();
      const { claims } = idToken.claims as { claims: Claims };
      this.setClaims(claims);
      return { claims };
    } catch (error) {
      this.logService.error('Unable to retrieve user ID token ', { error });
      return null;
    }
  }

  checkUserDeauthed(callback: (user: firebase.User) => any) {
    return this.firebaseAuth.onAuthStateChanged(callback);
  }

  async passwordSignup(email: string, password: string): Promise<firebase.auth.UserCredential> {
    const sessionName = `Session ${Math.ceil(Math.random() * 1000000)}`;
    const userSignUpSession = firebase.initializeApp(this.environment.firebase, sessionName);
    const credentials = await userSignUpSession.auth().createUserWithEmailAndPassword(email, password);
    userSignUpSession.auth().signOut();
    return credentials;
  }

  async emailSignup(email: string) {
    const actionCodeSettings = {
      url: `${window.location.origin}/auth`,
      handleCodeInApp: true,
    };
    try {
      this.firebaseAuth.sendSignInLinkToEmail(email, actionCodeSettings);
    } catch (error) {
      this.logService.error('Failed to send sign-in email', error);
    }
    LocalStorageUtil.setEmail(email);
  }

  async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    const user = await this.firebaseAuth.signInWithEmailAndPassword(email, password);
    return user;
  }

  async logout() {
    LocalStorageUtil.removeClaims();
    this.firebaseAuth.signOut();
  }

  async sendPasswordResetEmail(email: string) {
    const actionCodeSettings = {
      url: `${window.location.origin}`,
    };
    return this.firebaseAuth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  async verifyPasswordResetCode(code: string) {
    return this.firebaseAuth.verifyPasswordResetCode(code);
  }

  async confirmPasswordReset(code: string, newPassword: string) {
    return this.firebaseAuth.confirmPasswordReset(code, newPassword);
  }

  async verifyEmail(code: string) {
    await this.firebaseAuth.applyActionCode(code);
  }

  getCurrentUser() {
    return firebase.auth().currentUser;
  }

  generateConfigMeta(lockCodeChanged = false): AssetMeta {
    const configMeta = {
      configUpdated: firebase.firestore.FieldValue.serverTimestamp() as any,
      configUpdatedBy: this.getCurrentUser().email ?? this.getCurrentUser().uid,
      isConfigSynced: false,
    } as AssetMeta;
    if (lockCodeChanged) {
      configMeta.lockCodeUpdatedBy = configMeta.configUpdatedBy;
    }
    return configMeta;
  }

  private initUser(): Observable<User> {
    return this.firebaseAuth.idTokenResult.pipe(
      switchMap((idToken) => {
        if (idToken == null) {
          return of(null);
        }
        const { claims } = idToken.claims as { claims: Claims };
        if (claims == null) {
          return of(null);
        }
        let path: string;
        if (claims.role === Role.Admin || claims.role === Role.DeviceManager || claims.role === Role.Marketing) {
          path = Path.internalUser(this.getCurrentUser().uid);
        } else if (claims.scope === Scope.Organization) {
          path = Path.orgUser(claims.orgId, this.getCurrentUser().uid);
        } else if (claims.scope === Scope.Location) {
          path = Path.locUser(claims.orgId, claims.locId, this.getCurrentUser().uid);
        } else {
          this.logService.error('Unable to retrieve user based on claims ');
          return of(null);
        }
        return this.daoFactory.build(UserDAO, path).pipe(
          switchMap((x) => (x ? x.stream : of(null))),
          shareReplay(1),
        );
      }),
    );
  }

  private setClaims(claims: Claims) {
    if (claims) {
      LocalStorageUtil.setClaims(claims);
    }
  }
}
