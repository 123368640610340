import { ToastrService } from 'ngx-toastr';

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, LogService, LogZone } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { AppRoute } from '@shared';

@Component({
  selector: 'sc-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../auth.scss', './forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public AppRoute = AppRoute;
  email = '';
  invalid = false;
  loading = false;
  sent = false;

  // eslint-disable-next-line max-len
  emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private authService: AuthService,
    private logService: LogService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    activatedRoute: ActivatedRoute,
  ) {
    this.logService = this.logService.for(LogZone.AUTHENTICATION);
    if (activatedRoute.snapshot.queryParams.email) {
      this.email = activatedRoute.snapshot.queryParams.email;
    }
  }

  async sendEmail() {
    if (!this.email.match(this.emailRegex)) {
      this.invalid = true;
      return;
    }

    this.loading = true;
    this.logService.debug('Sending password reset info...');
    try {
      const sent = await this.authService.sendPasswordResetEmail(this.email);
      this.logService.debug('Password Reset info ', sent);
      this.logService.info('Password reset email sent.', { email: this.email });
      this.sent = true;
    } catch (error) {
      this.logService.error('Unable to send password reset email. ', { error });
      this.toastrService.error(this.translateService.instant('PASSWORD_RESET.ERROR.UNABLE_TO_RESET_PASSWORD'));
    }
    this.loading = false;
  }
}
