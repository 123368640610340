import { ExternalRole, InternalRole, TempUnit } from '../enums';

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  temp: TempUnit;
  role: ExternalRole | InternalRole;
  isSubscribedToAlerts: boolean;
  meridianTime: boolean;
}
