import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
  constructor(private decimal: DecimalPipe) {}

  transform(data: any, hourProp: string, minuteProp: string, meridian: boolean): string {
    const hour = data[hourProp];
    const minute = data[minuteProp];
    let sanitizedHour: string;
    const sanitizedMinute = this.decimal.transform(minute, '2.0');
    if (meridian) {
      sanitizedHour = this.decimal.transform(hour % 12 || 12, '1.0');

      return `${sanitizedHour}:${sanitizedMinute} ${hour >= 12 ? 'PM' : 'AM'}`;
    }

    sanitizedHour = this.decimal.transform(hour, '1.0');
    return `${sanitizedHour}:${sanitizedMinute}`;
  }
}
